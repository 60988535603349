<div [formGroup]="costsToBePaid">
  <p *ngIf="showMissingFeesMessage" class="text-danger">
    Please add the fee type that corresponds to your products where the fee is greater than zero
  </p>
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">Fee type</th>
        <th scope="col">When refundable?</th>
        <th scope="col">Amount Refundable</th>
        <th scope="col">Add fee to loan?</th>
        <th scope="col">When Payable?</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="costsToBePaid['controls'].length; else noItems">
        <ng-container *ngFor="let cost of costsToBePaid['controls']; index as i">
          <tr [formGroupName]="i">
            <td>
              <select
                class="form-control"
                formControlName="type"
                [ngClass]="{ 'is-valid': valid('type',i), 'is-invalid': invalid('type',i) }"
                required
              >
                <option [value]="null" hidden>Please select...</option>
                <option *ngFor="let fee of allFeeTypes; trackBy: trackByKey" [value]="fee.key">{{ fee.name }}</option>
              </select>
              <div *ngIf="invalid('type',i) && errors('type',i).required" class="invalid-feedback">
                Fee type is required.
              </div>
              <div *ngIf="invalid('type',i) && errors('type',i).pattern" class="invalid-feedback">
                Fee type is invalid.
              </div>
            </td>
            <td>
              <select
                class="form-control"
                formControlName="whenRefundable"
                [ngClass]="{ 'is-valid': valid('whenRefundable',i), 'is-invalid': invalid('whenRefundable',i) }"
                (change)="whenRefundableChange($event.target.value, i)"
                required
                >
                <option [value]="null" hidden>Please select...</option>
                <option *ngFor="let opt of whenRefundable | enumvalues; trackBy: trackByKey"
                  [value]="opt.key">
                  {{ opt.value | startcase }}
                </option>
              </select>
              <div *ngIf="invalid('whenRefundable',i)" class="invalid-feedback">
                When Refundable is required.
              </div>
            </td>
            <td>
              <div class="input-group">
                <span class="input-group-text">£</span>
                <input
                  type="text"
                  class="form-control"
                  formControlName="amountRefundable"
                  placeholder="0.00"
                  currencyMask
                  [currencyMaskAllowFractional]="true"
                  [ngClass]="{ 'is-valid': valid('amountRefundable',i), 'is-invalid': invalid('amountRefundable',i) }"
                  [readonly]="+value('whenRefundable', i) === whenRefundable.NotRefundable ? 'readonly' : null">
              </div>
              <div *ngIf="invalid('amountRefundable',i) && errors('amountRefundable',i).required" class="invalid-feedback">
                Refundable Amount is required when fee is refundable.
              </div>
              <div *ngIf="invalid('amountRefundable',i) && errors('amountRefundable',i).min" class="invalid-feedback">
                Refundable Amount must be at least £0.01.
              </div>
            </td>
            <td>
              <select
              class="form-control"
              formControlName="addToLoan"
              [ngClass]="{ 'is-valid': valid('addToLoan',i), 'is-invalid': invalid('addToLoan',i) }"
              required
              >
                <option value="null" hidden>Please select...</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
              <div *ngIf="invalid('addToLoan',i)" class="invalid-feedback">
                Add fee to loan is required.
              </div>
            </td>
            <td>
              <select
              class="form-control"
              formControlName="whenPayable"
              [ngClass]="{ 'is-valid': valid('whenPayable',i), 'is-invalid': invalid('whenPayable',i) }"
              required
              >
                <option [value]="null" hidden>Please select...</option>
                <option *ngFor="let opt of whenPayable | enumvalues; trackBy: trackByKey"
                  [value]="opt.key">
                  {{ opt.value | startcase }}
                </option>
              </select>
              <div *ngIf="invalid('whenPayable',i)" class="invalid-feedback">
                When Payable is required.
              </div>
            </td>
            <td>
              <button *ngIf="removingItem !== i" type="button" class="btn btn-danger" (click)="removingItem = i" [disabled]="disabled">Remove</button>
              <ng-container *ngIf="removingItem === i">
                <button type="button" class="btn btn-danger" (click)="removeFee(i)">Yes</button>
                <button type="button" class="ms-2 btn btn-warning" (click)="removingItem = null">No</button>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #noItems>
        <tr>
          <td colspan="100%" class="text-center">
            No fees has been added, please click the "Add new fee" button if you need to add some.
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
  <button type="button" class="btn btn-2022--secondary float-end mb-3" (click)="addItem()" [disabled]="disabled">
    Add new fee
  </button>
</div>
