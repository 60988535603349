import { Routes } from '@angular/router';

import { ContactPageComponent } from '@msslib/pages/contact-page/contact-page.component';
import { ErrorPageComponent } from '@msslib/pages/error-page/error-page.component';
import {
  authorizeGuard,
  disclaimerGuard,
  pendingChangesGuard,
  roleGuard,
} from '@msslib/services/guards';
import {
  AffordabilityManagementComponent,
  AllProductsComponent,
  AuditHistoryComponent,
  ConsumerDutyComponent,
  HomeComponent,
  MiCompletionsPaidComponent,
  MiGenericReportViewerComponent,
  MiHostedComponent,
  MiSurveyingServicesComponent,
  ProductUploadComponent,
} from './pages';
import { lendingTypeResolver } from './services';
import { roles } from '@msslib/constants';
import { ClientApps } from '@msslib/models';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.lender, roles.packager], client: ClientApps.LenderHub },
  },
  {
    path: 'ignite',
    loadChildren: () => import('apps/clubhub/src/app/ignite/ignite.module').then((m) => m.IgniteModule),
    canActivate: [authorizeGuard, disclaimerGuard],
    data: { roles: [roles.lender], client: ClientApps.LenderHub },
  },
  {
    path: 'product-upload',
    component: ProductUploadComponent,
    resolve: { lendingTypes: lendingTypeResolver },
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    canDeactivate: [pendingChangesGuard],
    data: { roles: [roles.product, roles.packager], client: ClientApps.LenderHub },
  },
  {
    path: 'bridging-product-upload',
    component: ProductUploadComponent,
    resolve: { lendingTypes: lendingTypeResolver },
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.product, roles.packager], client: ClientApps.LenderHub },
  },
  {
    path: 'audit-history',
    component: AuditHistoryComponent,
    resolve: { lendingTypes: lendingTypeResolver },
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.igniteAudit], client: ClientApps.LenderHub },
  },
  {
    path: 'esis',
    loadChildren: () => import('./pages/esis/esis.module').then((m) => m.ESISModule),
    resolve: { lendingTypes: lendingTypeResolver },
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.lenderEsisConfiguration], client: ClientApps.LenderHub },
  },
  {
    path: 'all-products',
    component: AllProductsComponent,
    resolve: { lendingTypes: lendingTypeResolver },
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.product], client: ClientApps.LenderHub },
  },
  {
    path: 'bridging-all-products',
    component: AllProductsComponent,
    resolve: { lendingTypes: lendingTypeResolver },
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.product], client: ClientApps.LenderHub },
  },
  {
    path: 'criteria',
    loadChildren: () => import('./pages/criteria/criteria.module').then((m) => m.CriteriaModule),
    resolve: { lendingTypes: lendingTypeResolver },
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.lender], client: ClientApps.LenderHub },
  },
  {
    path: 'criteria-v2',
    loadChildren:
      () => import('./pages/criteria-v2/criteria-v2.module').then((m) => m.CriteriaV2Module),
    resolve: { lendingTypes: lendingTypeResolver },
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.lender], client: ClientApps.LenderHub },
  },
  {
    path: 'mi/completions-paid',
    component: MiCompletionsPaidComponent,
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.lender, roles.lenderPaidMiRoles.paidCompletionsMi], client: ClientApps.LenderHub },
  },
  {
    path: 'mi/hosted',
    component: MiHostedComponent,
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.lender, roles.lenderPaidMiRoles.hostedMi], client: ClientApps.LenderHub },
  },
  {
    path: 'mi/surveying-services',
    component: MiSurveyingServicesComponent,
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.lender, roles.lenderPaidMiRoles.lGSSLenderSpecificMI, roles.lenderPaidMiRoles.lGSSNewBuildMI],
      client: ClientApps.LenderHub },
  },
  {
    path: 'mi/:reportpath',
    component: MiGenericReportViewerComponent,
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.lenderPaidMi], client: ClientApps.LenderHub },
  },
  {
    path: 'manage-affordability/:lendingTypeCode',
    component: AffordabilityManagementComponent,
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    canDeactivate: [pendingChangesGuard],
    data: { roles: [roles.lender, roles.affordabilityManager], client: ClientApps.LenderHub },
  },
  {
    path: 'consumer-duty',
    component: ConsumerDutyComponent,
    canActivate: [authorizeGuard, roleGuard, disclaimerGuard],
    data: { roles: [roles.lender, roles.consumerDuty], client: ClientApps.LenderHub },
  },
  { path: 'contact', component: ContactPageComponent, data: { isLenderHub: true } },
  { path: 'error', component: ErrorPageComponent },
];
