import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { editorConfig } from '../../editor.config';

@Component({
  selector: 'app-additional-obligations',
  templateUrl: 'additional-obligations.component.html',
  styleUrls: ['additional-obligations.component.scss'],
})

export class AdditionalObligationsComponent {
  @Input() public additionalObligations: UntypedFormGroup;
  @Input() public linkedSavings: UntypedFormGroup;
  @Input() public springBoard: UntypedFormGroup;
  @Input() public lenderUser: string | null;
  public editor = DecoupledEditor;
  public additionalObligationsEditorConfig = { ...editorConfig };
  public linkedSavingsEditorConfig = { ...editorConfig };
  public springBoardEditorConfig = { ...editorConfig };

  public constructor() {
    this.additionalObligationsEditorConfig.placeholder = 'Additional Obligations wording';
    this.linkedSavingsEditorConfig.placeholder = 'Linked Savings wording';
    this.springBoardEditorConfig.placeholder = 'Spring Board wording';
  }

  public get isBarclays() {
    return this.lenderUser === 'Barclays';
  }

  public onReady(editor) {
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element,
    );
  }

  public controlText(formControlName: string) {
    return this[formControlName].get('text');
  }

  public valid(formControlName: string, name:string): boolean {
    return this[formControlName].controls[name].valid && this[formControlName].controls[name].touched;
  }

  public invalid(formControlName: string, name:string): boolean {
    return this[formControlName].controls[name].invalid && this[formControlName].controls[name].touched;
  }
}
