import { Component } from '@angular/core';
import { AuthorizeService } from '@msslib/services/authorize.service';
import { roles } from '@msslib/constants';
import { MiGenericService } from '../mi-generic-report-viewer/mi-generic.service';

@Component({
  selector: 'app-mi-page-nav',
  styleUrls: ['mi-page-nav.component.scss'],
  templateUrl: 'mi-page-nav.component.html',
})
export class MiPageNavComponent {
  public constructor(
    private authorizeService: AuthorizeService,
  ) {}

  public get hasPaidCompletionsMi() {
    return this.authorizeService.hasRole(roles.lenderPaidMiRoles.paidCompletionsMi);
  }

  public get hasHostedMi() {
    return this.authorizeService.hasRole(roles.lenderPaidMiRoles.hostedMi);
  }

  public get hasLGSSLenderSpecificMi() {
    return this.authorizeService.hasRole(roles.lenderPaidMiRoles.lGSSLenderSpecificMI);
  }

  public get hasLGSSNewBuildMI() {
    return this.authorizeService.hasRole(roles.lenderPaidMiRoles.lGSSNewBuildMI);
  }

  public get hasLGSSMI() {
    return this.hasLGSSLenderSpecificMi || this.hasLGSSNewBuildMI;
  }

  public get genericReports() {
    return MiGenericService.reports
      .filter(r => this.authorizeService.hasRole(r.role));
  }
}
