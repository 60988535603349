
export const editorConfig = {
  placeholder: '',
  language: 'en',
  toolbar: {
    items: ['heading', 'fontSize', 'fontColor', 'fontBackgroundColor', 'bold', 'italic', 'underline', 'strikethrough',
      'alignment', 'numberedList', 'bulletedList', 'indent', 'outdent', 'link', 'blockQuote', 'insertTable', 'undo',
      'redo'],
    shouldNotGroupWhenFull: false,
  },
  plugins: ['Essentials', 'Alignment', 'FontSize', 'FontColor', 'FontBackgroundColor', 'Autoformat', 'Bold', 'Italic',
    'Strikethrough', 'Underline', 'BlockQuote', 'Heading', 'Indent', 'IndentBlock', 'Link', 'List', 'ListProperties',
    'Paragraph', 'PasteFromOffice', 'Table', 'TableToolbar'],
};
