<div [formGroup]="additionalObligations" class="mb-4 row">
  <label class="col-12" for="additionalObligationsText">Please provide additional obligations wording:</label>
  <div class="col-12">
    <ckeditor
      [editor]="editor"
      [config]="additionalObligationsEditorConfig"
      (ready)="onReady($event)"
      formControlName="text"
      id="additionalObligationsText"
      [ngClass]="{ 'is-valid': valid('additionalObligations', 'text'), 'is-invalid': invalid('additionalObligations', 'text') }"
      required
    >
    </ckeditor>
    <div *ngIf="invalid('additionalObligations', 'text') && controlText('additionalObligations')?.errors?.['required']" class="invalid-feedback">
      Additional Obligation wording is required.
    </div>
  </div>
</div>
<ng-container *ngIf="isBarclays">
  <div [formGroup]="linkedSavings">
    <div class="mb-4 row">
      <label class="col-12" for="linkedSavingsText">Please provide Linked Savings wording:</label>
      <div class="col-12">
        <ckeditor
          [editor]="editor"
          [config]="linkedSavingsEditorConfig"
          (ready)="onReady($event)"
          formControlName="text"
          id="linkedSavingsText"
          [ngClass]="{ 'is-valid': valid('linkedSavings', 'text'), 'is-invalid': invalid('linkedSavings', 'text') }"
          required
        >
        </ckeditor>
        <div *ngIf="invalid('linkedSavings', 'text') && controlText('linkedSavings')?.errors?.['required']" class="invalid-feedback">
          Linked Savings wording is required.
        </div>
      </div>
    </div>
  </div>
  <div [formGroup]="springBoard">
    <div class="mb-4 row">
      <label class="col-12" for="springBoardText">Please provide Spring Board wording:</label>
      <div class="col-12">
        <ckeditor
          [editor]="editor"
          [config]="springBoardEditorConfig"
          (ready)="onReady($event)"
          formControlName="text"
          id="springBoardText"
          [ngClass]="{ 'is-valid': valid('springBoard', 'text'), 'is-invalid': invalid('springBoard', 'text') }"
          required
        >
        </ckeditor>
        <div *ngIf="invalid('springBoard', 'text') && controlText('springBoard')?.errors?.['required']" class="invalid-feedback">
          Spring Board wording is required.
        </div>
      </div>
    </div>
  </div>
</ng-container>
