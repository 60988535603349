<div [formGroup]="flexibleFeatures">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col" class="col-3">Feature name</th>
        <th scope="col">Feature wording</th>
        <th scope="col" class="col-1 action-column">Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="flexibleFeatures['controls'].length; else noItems">
        <ng-container *ngFor="let cost of flexibleFeatures['controls']; index as i">
          <tr [formGroupName]="i">
            <td>
              <input
                type="text"
                class="form-control"
                placeholder="Feature name"
                formControlName="type"
                [ngClass]="{ 'is-valid': valid('type',i), 'is-invalid': invalid('type',i) }"
                required
              >
              <div *ngIf="invalid('type',i) && getControl('type', i).errors['required']" class="invalid-feedback">
                Feature name is required.
              </div>
              <div *ngIf="invalid('type',i) && getControl('type', i).errors['pattern']" class="invalid-feedback">
                Feature name is invalid.
              </div>
            </td>
            <td>
              <ckeditor
                [editor]="editor"
                [config]="editorConfig"
                (ready)="onReady($event)"
                formControlName="text"
                [ngClass]="{ 'is-valid': valid('text',i), 'is-invalid': invalid('text',i) }"
                required
              >
              </ckeditor>
              <div *ngIf="invalid('text',i) && getControl('text', i).errors['required']" class="invalid-feedback">
                Feature wording is required.
              </div>
            </td>
            <td>
              <button *ngIf="removingItem !== i" type="button" class="btn btn-danger" (click)="removingItem = i" [disabled]="disabled">Remove</button>
              <ng-container *ngIf="removingItem === i">
                <button type="button" class="btn btn-danger" (click)="removeFee(i)">Yes</button>
                <button type="button" class="ms-2 btn btn-warning" (click)="removingItem = null">No</button>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #noItems>
        <tr>
          <td colspan="100%" class="text-center">
            No flexible features has been added, please click the "Add more" button if you need to add some.
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
  <button type="button" class="btn btn-2022--secondary float-end mb-3" (click)="addItem()" [disabled]="disabled">
    Add more
  </button>
</div>
