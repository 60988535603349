import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { editorConfig } from '../../editor.config';

@Component({
  selector: 'app-additional-information',
  templateUrl: 'additional-information.component.html',
  styleUrls: ['additional-information.component.scss'],
})

export class AdditionalInformationComponent {
  @Input() public additionalInformation: UntypedFormGroup;
  public editor = DecoupledEditor;
  public editorConfig = { ...editorConfig };

  public constructor() {
    this.editorConfig.placeholder = 'Additional information wording';
  }

  public onReady(editor) {
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element,
    );
  }

  public get text() {
    return this.additionalInformation.get('text');
  }

  public valid(name:string): boolean {
    return this.additionalInformation.controls[name].valid && this.additionalInformation.controls[name].touched;
  }

  public invalid(name:string): boolean {
    return this.additionalInformation.controls[name].invalid && this.additionalInformation.controls[name].touched;
  }
}
