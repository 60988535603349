<div [formGroup]="earlyRepaymentCharges">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">Early repayment charge</th>
        <th scope="col">Fee amount</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="earlyRepaymentCharges['controls'].length; else noItems">
        <ng-container *ngFor="let cost of earlyRepaymentCharges['controls']; index as i">
          <tr [formGroupName]="i">
            <td>
              <input
                type="text"
                class="form-control"
                placeholder="Charge name"
                formControlName="charge"
                [ngClass]="{ 'is-valid': valid('charge',i), 'is-invalid': invalid('charge',i) }"
                required
              >
              <div *ngIf="invalid('charge',i) && getControl('charge',i).errors['required']" class="invalid-feedback">
                Early repayment charge is required.
              </div>
              <div *ngIf="invalid('charge',i) && getControl('charge',i).errors['pattern']" class="invalid-feedback">
                Early repayment charge is invalid.
              </div>
            </td>
            <td>
              <div class="input-group mb-2">
                <div class="input-group-text">£</div>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Amount"
                  formControlName="amount"
                  currencyMask
                  [ngClass]="{ 'is-valid': valid('amount',i), 'is-invalid': invalid('amount',i) }"
                  required
                  (blur)="focusAmount(i)"
                >
                <div *ngIf="invalid('amount',i) && getControl('amount',i).errors['required']" class="invalid-feedback">
                  Amount is required.
                </div>
              </div>
            </td>
            <td>
              <button *ngIf="removingItem !== i" type="button" class="btn btn-danger" (click)="removingItem = i" [disabled]="disabled">Remove</button>
              <ng-container *ngIf="removingItem === i">
                <button type="button" class="btn btn-danger" (click)="removeFee(i)">Yes</button>
                <button type="button" class="ms-2 btn btn-warning" (click)="removingItem = null">No</button>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #noItems>
        <tr>
          <td colspan="100%" class="text-center">
            No Early repayment charges has been added, please click the "Add new fee" button if you need to add some.
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
  <div class="text-end">
    <button type="button" class="btn btn-2022--secondary mb-3" (click)="addItem()" [disabled]="disabled">
      Add new fee
    </button>
  </div>
</div>

<div class="mt-2" [formGroup]="earlyRepaymentChargeTerms">
  <div class="mb-4 row">
    <label class="col-3" for="otherRights">ERC Additional terms and conditions:</label>
    <div class="col-8">
      <ckeditor
        [editor]="editor"
        [config]="editorConfig"
        (ready)="onReady($event)"
        id="earlyRepaymentChargeTerms"
        formControlName="text"
      >
      </ckeditor>
    </div>
  </div>
</div>
