import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ProductService } from 'apps/lenderhub/src/app/services';
import {
  BridgingLenderProducts,
  MatchedLenderProducts,
  ProductFilterKey,
} from 'apps/shared/src/models';
import { Animations } from '@msslib/animations/animations';
import { AuthorizeService } from '@msslib/services/authorize.service';
import { roles } from '@msslib/constants';
import { PaginationService } from '@msslib/services/pagination.service';
import { ActivatedRoute } from '@angular/router';
import { format } from 'date-fns';
import { bridgingProductTableColumns, resiAndBtlProductTableColumns } from './all-products.helper';
import { BridgingProductsHelper } from 'apps/shared/src/helpers/bridging-products.helper';
import { ISimpleGridColumn, ProductCollectionType, ProductUploadType } from '@msslib/models';

@Component({
  selector: 'app-all-products',
  templateUrl: 'all-products.component.html',
  styleUrls: ['all-products.component.scss'],
  animations: [Animations.tableOpenClose],
  providers: [PaginationService],
})
export class AllProductsComponent implements OnInit {
  public roles = roles;
  public productFilterKey = ProductFilterKey;

  public matchedProducts: MatchedLenderProducts | BridgingLenderProducts | null;
  public columnHeadings: string[];
  public finishedInitialLoading = false;
  public showScheduled = false;
  public startTime: string | null = null;
  public isBridging = false;
  public exclusiveOnly = false;
  public productTableColumns = resiAndBtlProductTableColumns;
  @ViewChild('typeTooltipContent', { static: true }) public typeTooltipContent: TemplateRef<unknown>;

  public constructor(
    private productService: ProductService,
    private authService: AuthorizeService,
    private activatedRoute: ActivatedRoute,
    public bridgingProductsHelper: BridgingProductsHelper,
  ) { }

  public ngOnInit() {
    this.isBridging = this.activatedRoute.snapshot.url.toString().includes('bridging');
    if (this.isBridging) {
      this.productTableColumns = bridgingProductTableColumns;
    } else {
      const typeColumn = this.productTableColumns.find(col => col.name === 'type');
      if (typeColumn) {
        typeColumn.headerTooltip = this.typeTooltipContent;
      }
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      const exclusiveOnly = params.exclusiveOnly;
      if (exclusiveOnly === 'true' || exclusiveOnly === true) {
        this.exclusiveOnly = exclusiveOnly;
      }

      const scheduled = params.scheduled;
      if (scheduled === 'true' || scheduled === true) {
        this.showScheduled = true;
        this.getScheduledProducts();
      } else {
        this.getAllProducts();
      }
    });

    if (this.hasRole(roles.packager) &&
    !this.productTableColumns.find((column: ISimpleGridColumn) => column.name === 'lender')) {
      this.productTableColumns.unshift({ name: 'lender', label: 'Lender', discreteFilter: true, sortable: true });
    }
  }

  public showScheduledProducts(showScheduledValue: boolean) {
    this.showScheduled = showScheduledValue;
    this.finishedInitialLoading = false;

    if (this.showScheduled) {
      this.getScheduledProducts();
    } else {
      this.getAllProducts();
    }
  }

  private getAllProducts() {
    const productCollectionType = this.isBridging ? ProductCollectionType.Bridging : ProductCollectionType.ResiBtl;
    const uploadType = this.exclusiveOnly ? ProductUploadType.Exclusive : ProductUploadType.Core;

    this.productService.getScheduled(productCollectionType, uploadType).subscribe((schedules) => {
      this.productService.getAllProducts(productCollectionType, uploadType).subscribe((products) => {
        if (schedules.current) {
          this.matchedProducts = products;
          this.startTime = schedules.current.startTime
            ? format(new Date(schedules.current.startTime), 'dd/MM/yyyy HH:mm')
            : null;
        } else {
          this.matchedProducts = null;
          this.startTime = null;
        }
        this.finishedInitialLoading = true;
      });
    });
  }

  private getScheduledProducts() {
    const productCollectionType = this.isBridging ? ProductCollectionType.Bridging : ProductCollectionType.ResiBtl;
    const uploadType = this.exclusiveOnly ? ProductUploadType.Exclusive : ProductUploadType.Core;

    this.productService.getScheduled(productCollectionType, uploadType).subscribe((schedules) => {
      if (schedules.future) {
        const startTime = schedules.future.startTime;
        this.productService.getScheduledProducts(productCollectionType, uploadType).subscribe((products) => {
          this.matchedProducts = products;
          this.startTime = startTime
            ? format(new Date(startTime), 'dd/MM/yyyy HH:mm')
            : null;
        });
      } else {
        this.matchedProducts = null;
        this.startTime = null;
      }

      this.finishedInitialLoading = true;
    });
  }

  public hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }
}
