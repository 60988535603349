import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lenderhub-home-card',
  styleUrls: ['home-card.component.scss'],
  templateUrl: 'home-card.component.html',
})
export class LenderHubHomeCardComponent {
  @Input() public image: string;
  @Input() public title: string;
}
