import { Component, OnInit } from '@angular/core';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { UntypedFormGroup } from '@angular/forms';
import { ToastService } from '@msslib/services/toast.service';
import { IInputOption } from '@msslib/components/formly/models/input';
import { IServiceLevelUpdates } from './../../models';
import { LenderServiceLevelUpdatesService } from './../../services';
import { AuthorizeService } from '@msslib/services';
import { roles } from '@msslib/constants';

import {
  LenderNameApplicationToOfferDefinition,
  LenderNameCreditType,
  LenderNameTimePeriod,
} from '@msslib/models/lender-name';
import { ServiceLevelUpdatesService } from '@msslib/services/service-level-update.service';
import { WrapperType } from '@msslib/components/formly/formly.config';

@Component({
  selector: 'app-lender-service-level-updates',
  templateUrl: './lender-service-level-updates.component.html',
  styleUrls: ['./lender-service-level-updates.component.scss'],
})
export class LenderServiceLevelUpdatesComponent implements OnInit {
  public form = new UntypedFormGroup({});
  public options: FormlyFormOptions = {};
  public fields: FormlyFieldConfig[] = [];
  public model: IServiceLevelUpdates = {} as IServiceLevelUpdates;

  public constructor(
    private lenderServiceLevelUpdatesService: LenderServiceLevelUpdatesService,
    private toastService: ToastService,
    private serviceLevelUpdatesService: ServiceLevelUpdatesService,
    private authorizeService: AuthorizeService,
  ) {}

  public ngOnInit(): void {
    if (this.authorizeService.hasRole(roles.packager)) {
      this.fields = this.getPackagerFields();
    } else {
      this.fields = this.getLenderFields();
    }

    this.getModel();
  }

  public submit() {
    if (this.form.valid) {
      this.lenderServiceLevelUpdatesService.updateServiceLevelUpdates(this.form.value).subscribe(() => {
        this.toastService.success('Service Level Updates saved');
        this.getModel();
      });
    }
  }

  private getModel() {
    this.lenderServiceLevelUpdatesService.getServiceLevelUpdates()
      .subscribe((results: IServiceLevelUpdates) => (this.model = results));
  }

  private get labels() {
    return this.serviceLevelUpdatesService.serviceLabels;
  }

  private getPackagerFields(): FormlyFieldConfig[] {
    return [
      this.selectField('canAcceptNonAdvisedReferrals', this.yesNoOptions),
      this.selectField('canUploadDocumentsOntoWebsite', this.yesNoOptions),
      this.selectField('hasLiveChat', this.yesNoOptions),
      this.compositeField('applicationToOffer'),
      this.compositeField('initialCaseAssessment'),
    ];
  }

  private getLenderFields(): FormlyFieldConfig[] {
    return [
      this.selectField('canAcceptApplicantOnline', this.yesNoOptions),
      this.selectField('canAcceptOnlineDipAip', this.yesNoOptions),
      this.selectField('canUploadDocumentsOntoWebsite', this.yesNoOptions),
      this.selectField('hasLiveChat', this.yesNoOptions),
      this.selectField('hasManualUnderwriter', this.yesNoOptions),
      this.selectField('creditType', this.creditOptions),
      this.selectField('applicationToOfferDefinition', this.applicationToOfferDefinitionOptions),
      this.compositeField('applicationToOffer'),
      this.compositeField('initialCaseAssessment'),
    ];
  }

  private selectField(key: string, options: IInputOption[]) {
    return {
      key,
      type: 'select',
      wrappers: ['table-row'],
      props: {
        label: this.labels[key],
        options,
      },
    };
  }

  private compositeField(key: string) {
    return {
      key,
      wrappers: ['table-row'],
      props: {
        label: this.labels[key],
        compositeControl: true,
      },
      fieldGroupClassName: 'row mx-0',
      fieldGroup: [
        {
          key: 'time',
          type: 'input',
          className: 'col-md-6 ps-0 pe-1',
          wrappers: [WrapperType.NoWrappers],
          props: {
            type: 'number',
            min: 0,
            label: this.labels[key],
          },
        },
        {
          key: 'period',
          type: 'select',
          className: 'col-md-6 ps-1 pe-0',
          wrappers: [WrapperType.NoWrappers],
          props: {
            options: this.timePeriodOptions,
          },
        },
      ],
    };
  }

  private get defaultSelectOption(): IInputOption {
    return { value: null, label: 'Please select...', disabled: true };
  }

  private get yesNoOptions(): IInputOption[] {
    return [this.defaultSelectOption, { value: true, label: 'Yes' }, { value: false, label: 'No' }];
  }

  private get creditOptions(): IInputOption[] {
    return [
      this.defaultSelectOption,
      {
        value: LenderNameCreditType.CreditSearch,
        label: this.serviceLevelUpdatesService.creditScoreLabels[LenderNameCreditType.CreditSearch],
      },
      {
        value: LenderNameCreditType.CreditScore,
        label: this.serviceLevelUpdatesService.creditScoreLabels[LenderNameCreditType.CreditScore],
      },
    ];
  }

  private get applicationToOfferDefinitionOptions(): IInputOption[] {
    return [
      this.defaultSelectOption,
      {
        value: LenderNameApplicationToOfferDefinition.DayOfApplicationReceiptToDayOfferIssued,
        label: this.serviceLevelUpdatesService.applicationToOfferDefinitionLabels[
          LenderNameApplicationToOfferDefinition.DayOfApplicationReceiptToDayOfferIssued
        ],
      },
      {
        value: LenderNameApplicationToOfferDefinition.DayAllRequiredDocumentationIsProvidedToDayOfferIssued,
        label: this.serviceLevelUpdatesService.applicationToOfferDefinitionLabels[
          LenderNameApplicationToOfferDefinition.DayAllRequiredDocumentationIsProvidedToDayOfferIssued
        ],
      },
      {
        value: LenderNameApplicationToOfferDefinition.CaseDependent,
        label: this.serviceLevelUpdatesService
          .applicationToOfferDefinitionLabels[LenderNameApplicationToOfferDefinition.CaseDependent],
      },
      {
        value: LenderNameApplicationToOfferDefinition.SeeLenderWebsite,
        label: this.serviceLevelUpdatesService
          .applicationToOfferDefinitionLabels[LenderNameApplicationToOfferDefinition.SeeLenderWebsite],
      },
    ];
  }

  private get timePeriodOptions(): IInputOption[] {
    return [
      this.defaultSelectOption,
      { value: LenderNameTimePeriod.Hours, label: 'Hours' },
      { value: LenderNameTimePeriod.Days, label: 'Days' },
    ];
  }
}
