import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { editorConfig } from '../../editor.config';

@Component({
  selector: 'app-non-compliance',
  templateUrl: 'non-compliance.component.html',
  styleUrls: ['non-compliance.component.scss'],
})

export class NonComplianceComponent {
  @Input() public nonCompliance: UntypedFormGroup;
  @Input() public nonComplianceCI: UntypedFormGroup;
  @Input() public nonComplianceIO: UntypedFormGroup;
  @Input() public lenderUser: string | null;
  public editor = DecoupledEditor;
  public editorConfig = { ...editorConfig };

  public constructor() {
    this.editorConfig.placeholder = 'Consequences for the borrower wording';
  }

  public get isHalifax() {
    return this.lenderUser === 'Halifax';
  }

  public get isScottishWidows() {
    return this.lenderUser === 'Scottish Widows';
  }

  public get isExtendedNonCompliance(): boolean {
    return this.isHalifax || this.isScottishWidows;
  }

  public onReady(editor) {
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element,
    );
  }

  public text(field: string) {
    return this[field].get('text');
  }

  public valid(field: string, name: string): boolean {
    return this[field].controls[name].valid && this[field].controls[name].touched;
  }

  public invalid(field: string, name:string): boolean {
    return this[field].controls[name].invalid && this[field].controls[name].touched;
  }
}
