import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { editorConfig } from '../../editor.config';

@Component({
  selector: 'app-other-rights-of-borrower',
  templateUrl: 'other-rights-of-borrower.component.html',
  styleUrls: ['other-rights-of-borrower.component.scss'],
})

export class OtherRightsOfBorrowerComponent {
  @Input() public otherRightsOfBorrower: UntypedFormGroup;
  public editor = DecoupledEditor;
  public editorConfig = { ...editorConfig };

  public constructor() {
    this.editorConfig.placeholder = 'Other rights wording';
  }

  public onReady(editor) {
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element,
    );
  }

  public valid(name:string): boolean {
    return this.otherRightsOfBorrower.controls[name].valid && this.otherRightsOfBorrower.controls[name].touched;
  }

  public invalid(name:string): boolean {
    return this.otherRightsOfBorrower.controls[name].invalid && this.otherRightsOfBorrower.controls[name].touched;
  }

  public getControl(name: string) {
    return this.otherRightsOfBorrower.get(name);
  }
}
