import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MssLibModule } from '@msslib/msslib.module';
import { HeaderComponent, LenderServiceLevelUpdatesComponent } from '.';

const components = [HeaderComponent, LenderServiceLevelUpdatesComponent];

@NgModule({
  imports: [MssLibModule, RouterModule],
  declarations: [...components],
  exports: [...components],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ComponentsModule {}
