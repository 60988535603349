<div class="row">
  <div class="col-3 col-xl-2">
    <ul ngbNav #nav="ngbNav" (navChange)="onNavChange($event)" class="nav-pills position-sticky" orientation="vertical" style="top: 0">
      <li ngbNavItem="fees">
        <a ngbNavLink>Fees (Tariff of Charges) - Read Only</a>
        <ng-template ngbNavContent>
          <lib-lender-tariff-of-charges
            [lenderNameId]="lenderNameId"
            (unsavedChangesChange)="hasUnsavedChanges = $event"
            [disabled]="true" />
        </ng-template>
      </li>

      <li ngbNavItem="floorRates">
        <a ngbNavLink>Floor Rates</a>
        <ng-template ngbNavContent>
          <div>
            <lib-floor-rates
              [lenderNameId]="lenderNameId"
              (unsavedChangesChange)="hasUnsavedChanges = $event" />
          </div>
        </ng-template>
      </li>

      <li ngbNavItem="variableRateCodes">
        <a ngbNavLink>Variable Rate Codes</a>
        <ng-template ngbNavContent>
            <lib-variable-rate-codes
              (unsavedChangesChange)="hasUnsavedChanges = $event" />
        </ng-template>
      </li>

      <li *ngIf="isProductTransferAllowed" ngbNavItem="productTransferDetails">
        <a ngbNavLink>Product Transfer Details</a>
        <ng-template ngbNavContent>
          <lib-product-transfer-details [lenderNameId]="lenderNameId"
                                        (unsavedChangesChange)="hasUnsavedChanges = $event"></lib-product-transfer-details>
        </ng-template>
      </li>

      <li *ngIf="isPackagerRelationshipsAllowed" ngbNavItem="packagerRelationships">
        <a ngbNavLink>Packager Relationship(s)</a>
        <ng-template ngbNavContent>
          <lib-packager-relationships [lenderNameId]="lenderNameId"
                                      (unsavedChangesChange)="hasUnsavedChanges = $event"></lib-packager-relationships>
        </ng-template>
      </li>

    </ul>
  </div>

  <div class="col">
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
