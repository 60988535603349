import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '../../../sentry';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: 'https://6985d9641ccc4ec284cf0a1acbc95265@sentry.theidol.com/63',
    attachStacktrace: true,
    debug: false,
    environment: 'production',
    maxValueLength: 5000,
    // // Set tracesSampleRate to 1.0 to capture 100%
    // // of transactions for performance monitoring.
    // // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}


const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  if ((module as any).hot) {
    hmrBootstrap(module, bootstrap);
  } else {
    // eslint-disable-next-line no-console
    console.error('HMR is not enabled for webpack-dev-server!');
    // eslint-disable-next-line no-console
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  // eslint-disable-next-line no-console
  bootstrap().catch(err => console.log(err));
}
