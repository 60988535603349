<div [formGroup]="otherRightsOfBorrower">
  <div class="mb-4 row">
    <label class="col-3" for="reflectionPeriod">Length of reflection period:</label>
    <div class="col-4">
      <div class="row">
        <div class="col">
          <input
            formControlName="reflectionPeriod"
            type="number"
            class="form-control"
            id="reflectionPeriod"
            placeholder="Reflection period"
            [ngClass]="{ 'is-valid': valid('reflectionPeriod'), 'is-invalid': invalid('reflectionPeriod') }"
            required
          >
          <div *ngIf="invalid('reflectionPeriod') && (getControl('reflectionPeriod')?.errors['required'])" class="invalid-feedback">
            Length of reflection period is required.
          </div>
          <div *ngIf="invalid('reflectionPeriod') && getControl('reflectionPeriod')?.errors['pattern']" class="invalid-feedback">
            Length of reflection period should only contain numeric characters.
          </div>
        </div>
        <div class="col my-auto">
          <span class="ps-0">Days</span>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-4 row">
    <label class="col-3" for="otherRights">Other rights:</label>
    <div class="col-8">
    <ckeditor
      [editor]="editor"
      [config]="editorConfig"
      (ready)="onReady($event)"
      id="otherRights"
      formControlName="otherRights"
      [ngClass]="{ 'is-valid': valid('otherRights'), 'is-invalid': invalid('otherRights') }"
      required
    >
    </ckeditor>
    <div *ngIf="invalid('otherRights') && getControl('otherRights')?.errors['required']" class="invalid-feedback">
      Other rights wording is required.
    </div>
    <div *ngIf="invalid('otherRights') && getControl('otherRights')?.errors['pattern']" class="invalid-feedback">
      Other rights wording is invalid.
    </div>
    </div>
  </div>
</div>