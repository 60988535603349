<nav class="navbar navbar-expand-lg">
  <div>
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" routerLink="/home">
          <i class="fas fa-arrow-left"></i>
          Back
        </a>
      </li>
      <li *ngIf="hasPaidCompletionsMi" class="nav-item">
        <a class="nav-link active" routerLink="/mi/completions-paid" routerLinkActive="tab-active">
          Completions MI
        </a>
      </li>
      <li *ngIf="hasHostedMi" class="nav-item">
        <a class="nav-link active" routerLink="/mi/hosted" routerLinkActive="tab-active">
          Hosted MI
        </a>
      </li>
      <li *ngIf="hasHostedMi || hasLGSSMI" class="nav-item">
        <a class="nav-link active" routerLink="/mi/surveying-services" routerLinkActive="tab-active">
          Surveying Services MI
        </a>
      </li>
      <li *ngFor="let report of genericReports" class="nav-item">
        <a class="nav-link active" [routerLink]="'/mi/' + report.path" routerLinkActive="tab-active">
          {{ report.displayName }}
        </a>
      </li>
    </ul>
  </div>
</nav>
