import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LenderName } from 'apps/shared/src/models';
import { LendingType } from '@msslib/models';

@Component({
  selector: 'app-products-visibility-checkbox',
  templateUrl: './products-visibility-checkbox.component.html',
})
export class ProductsVisibilityCheckboxComponent implements OnInit {
  @Input() public lenderName: LenderName | null;
  @Input() public lendingTypeCode: string;
  @Input() public lendingTypeName: string;
  @Input() public lendingTypes: LendingType[];
  @Input() public isBridging = false;
  @Input() public hasSchedule = false;
  @Input() public isChecked = false;
  @Input() public productsVisibilityEnabled = true;
  @Input() public existingProducts: Record<string, number> = {};

  @Output() public showProductsChange = new EventEmitter<{value: boolean; lendingTypeCode: string}>();

  private readonly bridgingLendingTypeCode = 'Bdg';

  public isCheckboxVisible = false;
  public tooltipMessage = '';

  public ngOnInit(): void {
    this.initCheckboxState();
  }

  public changeState(value: boolean) {
    this.showProductsChange.emit({value: value, lendingTypeCode: this.lendingTypeCode});
  }

  private initCheckboxState() {
    if (!this.lenderName) {
      return;
    }

    this.isCheckboxVisible = this.isProductVisibilityAvailable(this.lendingTypeCode);
    if (!this.isCheckboxVisible) {
      return;
    }

    if (!this.productsVisibilityEnabled) {
      this.tooltipMessage = 'These products have been switched off, ' +
        // eslint-disable-next-line spellcheck/spell-checker
        'if the reasoning is not known please contact LGMStechsupport@landg.com';
    }
  }

  public get productsVisibilityDisabled() {
    return (!this.isShowProductEnabled(this.lendingTypeName) && !this.hasSchedule) || !this.productsVisibilityEnabled;
  }

  private isProductVisibilityAvailable(lendingType: string): boolean {
    return this.isBridging
      ? lendingType === this.bridgingLendingTypeCode
      : ['RES', 'BTL'].includes(lendingType);
  }

  private isShowProductEnabled(lendingTypeName: string) {
    return this.existingProducts[this.formatLendingTypeString(lendingTypeName)];
  }

  private formatLendingTypeString(lendingTypeName: string) {
    return lendingTypeName.split(' ').join('').toLowerCase();
  }
}
