<div [formGroup]="externalReferenceRate.parent">
  <p>The APRC external reference rate will be used to calculate the APRC in <strong>Section 4. Interest rate and other costs</strong> and the increase to monthly payments in <strong>Section 6. Amount of each installment</strong>.</p>

  <div class="mb-4 row">
    <label class="col-3" for="externalReferenceRate">External Reference Rate</label>
    <div class="col-4">
      <div class="input-group">
        <input
          formControlName="externalReferenceRate"
          type="number"
          class="form-control"
          [class.is-valid]="valid"
          [class.is-invalid]="invalid"
          id="externalReferenceRate"
          placeholder="Rate"
        >
        <span class="input-group-text">%</span>
      </div>
      <div *ngIf="invalid && externalReferenceRate?.errors['required']" class="invalid-feedback">
        External reference rate is required.
      </div>
      <div *ngIf="invalid && externalReferenceRate?.errors['min']" class="invalid-feedback">
        External reference rate must be a positive number.
      </div>
      <div *ngIf="invalid && externalReferenceRate?.errors['max']" class="invalid-feedback">
        External reference rate must be less than 100%.
      </div>
    </div>
  </div>
</div>