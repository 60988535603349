import { AfterViewInit, Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { ProductService } from 'apps/lenderhub/src/app/services';
import { AuthorizeService, ModalService } from '@msslib/services';
import { AnalyticsService } from '@msslib/services/analytics.service';
import { roles } from '@msslib/constants/roles';
import { UploadedProductsResult } from '@msslib/models/asanto-product-upload';
import { format } from 'date-fns';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ProductCollectionType,
  ProductUploadAdditionalResult,
  ProductUploadType,
  ProductsVisibilityState,
} from '@msslib/models';
import {
  PreviewUploadModalComponentResult,
} from '@msslib/components';
import { Observable, firstValueFrom, of } from 'rxjs';
import { LenderHubService } from 'apps/shared/src/services/lenderhub.service';
import { LenderProductsAdminComponent } from './components';
import { VariableRateCodeLookupViewModel } from '@msslib/models/variable-rate-codes';

@Component({
  selector: 'app-product-upload',
  templateUrl: 'product-upload.component.html',
  styleUrls: ['product-upload.component.scss'],
})
export class ProductUploadComponent implements OnInit, AfterViewInit {
  @ViewChild('valuationFeeModalTemplateRef', { static: true }) public valuationFeeModalTemplateRef: TemplateRef<void>;
  @ViewChild('valuationFeeIntroductionModalTemplateRef',
    { static: true }) public valuationFeeIntroductionModalTemplateRef: TemplateRef<void>;
  @ViewChild('valuationFeeErrorModalTemplateRef',
    { static: true }) public valuationFeeErrorModalTemplateRef: TemplateRef<void>;
  @ViewChild('previewUploadModalTemplateRef', { static: true }) public previewUploadModalTemplateRef: TemplateRef<void>;
  @ViewChild('deleteUploadModalTemplateRef', { static: true }) public deleteUploadModalTemplateRef: TemplateRef<void>;

  @ViewChild('payrateUpdateModalTemplateRef', { static: true }) public payrateUpdateModalTemplateRef: TemplateRef<void>;
  @ViewChild('variableRatesValidationModalTemplateRef',
    { static: true }) public variableRatesValidationModalTemplateRef: TemplateRef<void>;

  @ViewChild('lenderProductsAdminModalTemplate') public lenderProductsAdminModalTemplate: TemplateRef<void>;
  @ViewChild('lenderProductsAdminComponent') public lenderProductsAdminComponent: LenderProductsAdminComponent;

  public isSeparateExclusiveUpload: boolean;
  public uploadResult: UploadedProductsResult;
  public statusMessage: string[] | null = null; // Each element is a paragraph
  public errorOccurred = false;
  public validationErrors: string[] | null;
  public productsVisibilityStates: ProductsVisibilityState[];
  public existingProducts: Record<string, number> = {};
  public roles = roles;
  public isBridging = false;
  public hasValuationFee = false;
  public additionalResult: ProductUploadAdditionalResult;
  public missingEsisLendingTypes: string[];

  public variableRateCodes: VariableRateCodeLookupViewModel;

  private readonly bridgingLendingTypeCode = 'Bdg';
  private readonly bridgingLendingType = 'Bridging';

  public constructor(
    private productService: ProductService,
    private authService: AuthorizeService,
    private analyticsService: AnalyticsService,
    private modalService: ModalService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private lenderHubService: LenderHubService,
  ) {
  }

  public ngOnInit() {
    this.isBridging = this.activatedRoute.snapshot.url.toString().includes('bridging');
    this.initialisePage();
    this.updateValuationFeeScaleStatus();

    this.isSeparateExclusiveUpload = this.lenderHubService.lenderName?.separateExclusiveUpload || false;
  }

  public get isPackager() {
    return this.authService.hasRole(roles.packager);
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return !this.productService.unsavedChanges;
  }

  public canDeactivateMessage() {
    // eslint-disable-next-line no-alert
    return confirm('WARNING: You have unsaved changes. ' +
      'Press Cancel to go back and save these changes, or OK to lose these changes.');
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeNavigate(event) {
    if (this.productService.unsavedChanges) {
      event.preventDefault();
      event.returnValue = '';
    } else {
      return event;
    }
  }

  public initialisePage() {
    if (!this.hasRole(roles.packager)) {
      this.productService.visibleProductLendingTypes.subscribe((visibilityStates: ProductsVisibilityState[]) => {
        this.productsVisibilityStates = visibilityStates;
      });
    }

    if (this.isBridging) {
      this.productService.getAllBridgingProducts(ProductUploadType.Core).subscribe(results => {
        results?.products?.forEach(() => {
          this.updateExistingProducts(this.bridgingLendingType);
        });
      });
    } else {
      this.productService.getAllResAndBtlProducts(ProductUploadType.Core).subscribe(results => {
        results?.products?.forEach(product => {
          this.updateExistingProducts(product.lendingType);
        });
      });
    }

    this.fetchVariableRateCodes();
  }

  public closeValuationFeeModal(saveValuationFeeCallback: (() => Observable<void>) | null): void {
    this.modalService.close(saveValuationFeeCallback);
  }

  public updateValuationFeeScaleStatus() {
    // TODO: when valuation fee UI for resi/btl is implemented, remove this
    if (!this.isBridging) {
      this.hasValuationFee = false;
      return;
    }

    this.productService.getValuationFeeScales(this.bridgingLendingTypeCode).subscribe(
      scales => this.hasValuationFee = scales.some(s => !s.isScheduled));
  }

  private updateExistingProducts(lendingType: string) {
    if (!this.existingProducts[this.formatLendingTypeString(lendingType)]) {
      this.existingProducts[this.formatLendingTypeString(lendingType)] = 1;
    } else {
      this.existingProducts[this.formatLendingTypeString(lendingType)]++;
    }
  }

  public hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  public async uploadFile(uploadData: { files: FileList; uploadType: ProductUploadType }) {
    const { files, uploadType } = uploadData;
    if (files.length === 0) {
      return;
    }

    const fileToUpload = files.item(0);
    if (!fileToUpload) {
      return;
    }

    this.validationErrors = null;
    this.statusMessage = null;
    this.errorOccurred = false;

    try {
      // First, parse the product sheet and get the preview
      this.uploadResult = await firstValueFrom(
        this.productService.uploadProducts(
          fileToUpload,
          this.productCollectionType,
          uploadType,
          true,
        ),
      );

      //Show the variable rate warning if applicable. If the value returned is false - cancel upload
      if (this.uploadResult?.additionalFileProcessingResult?.variableRateWarnings?.length > 0) {
        const shouldContinueUpload = await this.openVariableRatesValidationModal();
        if (!shouldContinueUpload) {
          this.statusMessage = ['Upload cancelled.'];
          return await this.openStatusModal();
        }
      }


      // Next, if required, show the valuation fee scale modal
      const updateValuationFeeCallback = await this.openValuationFeeScaleModal();
      if (!updateValuationFeeCallback) {
        return;
      }


      // Next, show the preview modal
      const { confirmUpload, schedule, variableRateCodes, hasMissingVariableRateCodes } = await this.openPreviewModal();
      if (!confirmUpload) {
        this.statusMessage = ['Your product list has not been uploaded.'];
        return await this.openStatusModal();
      }

      // If the user confirms the upload, now upload for real
      this.additionalResult = await firstValueFrom(
        this.productService.uploadProducts(
          fileToUpload,
          this.productCollectionType,
          uploadType,
          false,
          schedule,
          variableRateCodes ? { codes: variableRateCodes, goLiveAt: schedule } : null,
          hasMissingVariableRateCodes ?? null,
        ),
      );

      // Show the user confirmation
      this.updateProductUploadStatusMessages(schedule);
      await this.openStatusModal();

      updateValuationFeeCallback().subscribe(() => {
        this.updateValuationFeeScaleStatus();
      });

      if (this.missingEsisLendingTypes?.length > 0) {
        this.router.navigate(['/esis'], {
          queryParams: { missingEsisLendingTypes: this.missingEsisLendingTypes },
        });
        return;
      }

      // If user has relevant roles, show payrate confirmation dialog
      if (await this.showPayrateUpdateModal()) {
        return; // User is being navigated to manage affordability. Don't navigate them home.
      }

      // Finally, redirect the user back to the home screen
      await this.router.navigate(['/home']);

    } catch (error) {
      switch (error.status) {
        case 400:
          this.validationErrors = [];
          this.statusMessage = ['The selected file could not be uploaded: '];
          if (error.error.error.data.validationErrors?.ValidationErrors) {
            for (const validationError of error.error.error.data.validationErrors.ValidationErrors) {
              this.validationErrors.push(validationError);
            }
          } else {
            this.statusMessage.push(error.error.error.message);
          }
          break;

        case 500:
          this.statusMessage = ['An unexpected error happened when trying to upload your products.'];
          let exception = error.error.error.data.innerException;
          while (exception) {
            this.statusMessage.push(exception.message);
            exception = exception.data?.innerException;
          }
          break;

        case 0:
          this.statusMessage = [
            'There was a problem during product upload. ' +
            'Your products may not have been uploaded, please check and retry if not.',
          ];
          break;

        default:
          this.statusMessage = ['Error, we were unable to upload your products.'];
          break;
      }

      if (!!error.error?.error?.requestId) {
        this.statusMessage.push(`Request ID: ${error.error.error.requestId}`);
      }

      this.errorOccurred = true;
      return await this.openStatusModal();
    }
  }

  /** @returns Whether the upload process should continue. */
  private async openValuationFeeScaleModal(): Promise<(() => Observable<void>) | null> {
    // Currently this is only applicable to bridging products
    if (!this.isBridging) {
      return () => of();
    }

    // If lender has already filled in the valuation fee scale, don't need to show the window again
    if (this.hasValuationFee) {
      return () => of();
    }

    // If none of the uploaded products require a valuation fee scale (either they have free val, or a flat valuation
    // fee is provided), then don't need to show the valuation window
    const anyProductRequiresScale = this.uploadResult.products
      .some(p => p.freeVal === false && !p.valuation_fee);
    if (!anyProductRequiresScale) {
      return () => of();
    }

    // Modal informing the user they need to enter the valuation fee, or cancel the upload
    const shouldUpdateValuationFeeScale = await this.modalService.open<boolean>({
      title: 'Product Upload',
      template: this.valuationFeeIntroductionModalTemplateRef,
      size: 'md',
    }).catch(() => false);
    if (!shouldUpdateValuationFeeScale) {
      return null;
    }

    // Actual valuation fee scale edit modal
    return await this.modalService.open<() => Observable<void>>({
      title: 'Update Valuation Fee Scale',
      template: this.valuationFeeModalTemplateRef,
      size: 'xl',
    })
      .then(updateCallback => {
        if (!updateCallback) {
          return null;
        }
        return updateCallback;
      })
      .catch(() => null);
  }

  private async openVariableRatesValidationModal(): Promise<boolean> {
    return await this.modalService.open<Observable<void>>({
      title: 'Variable products',
      template: this.variableRatesValidationModalTemplateRef,
      size: 'md',
      showButtons: true,
      cancelLabel: 'Cancel Upload - stop upload',
      okLabel: 'Approved - continue with upload',
    }).then(() => {
      return true;
    },
    () => {
      return false;
    })
      .catch(() => false);
  }

  private openPreviewModal(): Promise<PreviewUploadModalComponentResult> {
    return this.modalService.open<PreviewUploadModalComponentResult>({
      title: 'Preview product upload',
      template: this.previewUploadModalTemplateRef,
      size: 'xl',
    }).catch(() => ({ confirmUpload: false, schedule: null }));
  }

  private openStatusModal(): Promise<void> {
    return this.modalService.open({
      title: 'Product Upload',
      template: this.previewUploadModalTemplateRef,
      size: 'md',
    }).catch(() => { /* do not throw if modal gets closed */
    });
  }

  /** @returns A promise that resolves when the pay rate modal is closed. The boolean indicates whether the modal is re-
   * directing the user to the affordability management screen. In this case, the caller should not do any redirects. */
  private showPayrateUpdateModal(): Promise<boolean> {
    return this.modalService.open<boolean>({
      title: 'Payrate Update',
      template: this.payrateUpdateModalTemplateRef,
      size: 'md',
    }).catch(() => false);
  }
  private updateProductUploadStatusMessages(scheduleDateTime: string | null) {
    const hasProductsTurnedOff = this.productsVisibilityStates?.some(x => !x.showProducts) ?? false;

    if (hasProductsTurnedOff) {
      if (scheduleDateTime) {
        this.statusMessage = [
          `Your products have been successfully submitted for upload on
          ${format(new Date(scheduleDateTime), 'dd/MM/yyyy HH:mm')}.`,
        ];
      } else {
        this.statusMessage = [
          'Your products have been successfully submitted for upload.',
        ];
      }

      this.getProductVisibilityMessage();

    } else if (!hasProductsTurnedOff) {
      if (scheduleDateTime) {
        this.statusMessage = [
          `Your products have been successfully submitted for upload and will be live on
          ${format(new Date(scheduleDateTime), 'dd/MM/yyyy HH:mm')}.`,
          'You can audit or view your scheduled products in the next few minutes from the LenderHub home page.',
        ];
      } else {
        this.statusMessage = [
          'Your products have been successfully submitted for upload and will be live in the next few minutes ' +
          'for audit or to view on the "View all products" page.',
        ];
      }
    }
  }

  public getProductVisibilityMessage() {
    const resActive = this.productsVisibilityStates?.find(x => x.lendingTypeCode === 'RES')?.showProducts;
    const btlActive = this.productsVisibilityStates?.find(x => x.lendingTypeCode === 'BTL')?.showProducts;

    if (!btlActive && !resActive) {
      this.statusMessage?.push(`Your Residential and Buy to Let products are currently switched off
      and not visible to brokers in Legal & General Ignite. If you want your products visible in
      Legal & General Ignite please switch them on.`);
    } else if (btlActive && !resActive) {
      this.statusMessage?.push(`Your Residential products are currently switched off and
      not visible to brokers in Legal & General Ignite. If you want your products visible in
      Legal & General Ignite please switch them on.`);
    } else if (!btlActive && resActive) {
      this.statusMessage?.push(`Your Buy to Let products are currently switched off and not visible
      to brokers in Legal & General Ignite. If you want your products visible in
      Legal & General Ignite please switch them on.`);
    }
  }

  public ngAfterViewInit() {
    this.analyticsService.log('LenderHub Product Upload');

    this.lenderHubService.displayProductsUploadWarning();
  }

  public formatLendingTypeString(lendingType: string) {
    return lendingType.split(' ').join('').toLowerCase();
  }

  public dismissModal(result: unknown) {
    this.modalService.close(result);
  }

  public get productCollectionType(): ProductCollectionType {
    return this.isBridging
      ? ProductCollectionType.Bridging
      : ProductCollectionType.ResiBtl;
  }

  public get pageTitle(): string {
    return this.isBridging
      ? 'Manage Bridging Products'
      : 'Manage Residential and Buy To Let products';
  }

  public get productVisibilityText(): string {
    return this.isBridging
      ? 'Select to show or hide your Bridging products to Brokers in Legal & General Ignite'
      : 'Select which product types will be shown to brokers in Legal & General Ignite+.';
  }

  public get manageScheduledProductsTitle(): string {
    return this.isBridging
      ? 'Manage scheduled Bridging products'
      : 'Manage scheduled Residential and Buy To Let products';
  }

  public get manageScheduledBtnText(): string {
    return this.isBridging
      ? 'View scheduled Bridging products'
      : 'View scheduled Residential and Buy To Let products';
  }

  public setAmendFeesSelected(missingEsisLendingTypes: string[]): void {
    this.missingEsisLendingTypes = missingEsisLendingTypes;
  }

  public productAdmin() {
    this.modalService.open({
      title: `Product Admin - ${this.lenderHubService.lenderName?.name}`,
      size: 'xl',
      windowClass: 'lender-products-admin-modal',
      sticky: true,
      template: this.lenderProductsAdminModalTemplate,
      beforeDismiss: async () => await this.lenderProductsAdminComponent.canCloseActiveTab(),
    });
  }

  public get lenderNameId() {
    return this.lenderHubService.lenderName?.lenderNameId;
  }

  private fetchVariableRateCodes() {
    this.productService.getVariableRateCodes()
      .subscribe(result => {
        this.variableRateCodes = result;
      });
  }
}
