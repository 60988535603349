import { Injectable } from '@angular/core';

import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import { IEmbedConfig, IMiHostedReport } from './mi-hosted.model';

@Injectable()
export class MiHostedService {
  public constructor(private dataService: LenderHubDataService) {}

  public getReports() {
    return this.dataService.get<IMiHostedReport[]>('powerbi/GetHostedReports');
  }

  public getReportConfiguration(reportId: string) {
    return this.dataService.get<IEmbedConfig>(`powerbi/GetHostedReportConfiguration/${reportId}`);
  }
}
