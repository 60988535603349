<lib-page-nav
  [title]="bannerTitle"
></lib-page-nav>

<div class="container my-5">
  <div class="alert alert-danger" *ngIf="isReadOnly">
    Changes are currently being made to your affordability calculations and it is therefore not possible to make any
    further changes at this time. Please contact us to discuss.
  </div>

  <div class="alert alert-primary mb-3" *ngIf="hasScheduledChanges">
    <h4 class="alert-heading mb-2">Scheduled Changes</h4>
      <p>
        A payrate update is scheduled to go live on {{ activeScheduleDate | date : 'dd/MM/yyyy' }} at {{ activeScheduleDate | date : 'HH:mm' }}.
      </p>
      <div class="text-end">
        <button class="btn btn-outline-secondary me-2" (click)="showUpdateScheduleModal()">Reschedule</button>
        <button class="btn btn-outline-danger" (click)="discardSchedule()">Delete</button>
      </div>
    </div>
    <div class="p-3 bg-light" *ngIf="tables || taxTables">
      <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" [(activeIds)]="activeAccordions">        <!-- Loop through table titles -->
        <ng-container *ngFor="let text of combinedTitlesAndDescriptions">
          <ngb-panel title={{text.title}}>
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="accordion-button custom-header" [class.collapsed]="!opened">
                <!-- Styling for title and description on 2 seperate lines -->
                <div class="title">
                  <h3 class="m-0">{{text.title}}</h3>
                <button ngbPanelToggle class="btn btn-2022--secondary" style="right: auto;">{{ opened ? 'Collapse' : 'Expand' }}</button>
                </div>
                <div class="description">
                  <p class="m-0">{{text.description}}</p>
                </div>
              </div>
            </ng-template>
            <!-- Main accordion content -->
            <ng-template ngbPanelContent>
              <!-- Match only regular table titles -->
              <ng-container *ngFor="let table of tables; index as tableIndex">
                <form *ngIf="table.title === text.title" [formGroup]="tableFormArrays[tableIndex]">
                  <ng-container *ngIf="showTables(table.isMatchingWithIgnite) else error">
                    <table class="table table-bordered">
                      <thead>
                        <!-- If there are scheduled changes, show an additional column for each editable column; one for currently live and one for scheduled. -->
                        <tr>
                          <th
                            *ngFor="let column of table.columns"
                            [attr.colspan]="hasScheduledChanges && column.editable ? 2 : 1"
                            [attr.rowspan]="hasScheduledChanges && !column.editable ? 2 : 1"
                          >
                            {{ column.header }}
                          </th>
                        </tr>
                        <tr *ngIf="hasScheduledChanges">
                          <ng-container *ngFor="let column of table.columns">
                            <ng-container *ngIf="column.editable">
                              <th>Live</th>
                              <th>Scheduled</th>
                            </ng-container>
                          </ng-container>
                        </tr>
                      </thead>
                      <tbody class="bg-white">
                        <tr *ngFor="let row of table.data; index as rowIndex" [formGroupName]="rowIndex">
                          <ng-container *ngFor="let column of table.columns; index as colIndex">
                            <!-- Column with current value. Show in red if there are scheduled changes that alter this value.
                            Explicitly using != instead of !== because Asanto sometimes returns strings instead of numbers for example. -->
                            <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
                            <td class="align-middle" [class.table-danger]="hasScheduledChanges && row[colIndex] != table.scheduledData[rowIndex][colIndex]">
                              <ng-container [ngSwitch]="true">
                                <ng-container *ngSwitchCase="column.editable && column.type === columnType.Boolean && !hasScheduledChanges">
                                  <input
                                    type="checkbox"
                                    [formControlName]="colIndex"
                                  />
                                </ng-container>
                                <ng-container *ngSwitchCase="column.editable && column.type === columnType.Number && !hasScheduledChanges">
                                  <input
                                    type="number"
                                    class="form-control"
                                    [class.is-valid]="isValid(tableIndex, rowIndex, colIndex)"
                                    [class.is-invalid]="isInvalid(tableIndex, rowIndex, colIndex)"
                                    [formControlName]="colIndex"
                                    (keydown)="preventUnwanted($event)"
                                  />
                                </ng-container>
                                <ng-container *ngSwitchCase="hasScheduledChanges || !column.editable">
                                  {{ formatAsantoValue(row[colIndex], column.type) }}
                                </ng-container>
                              </ng-container>
                              <div class="invalid-feedback" *ngIf="controlErrors(tableIndex, rowIndex, colIndex)?.required">
                                Please enter a valid {{ column.header }}
                              </div>
                            </td>

                            <!-- If column is editable and changes are scheduled, show a diff column with scheduled data.
                            Show in green if there are scheduled changes that alter this value.
                            Explicitly using != instead of !== because Asanto sometimes returns strings instead of numbers for example. -->
                            <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
                            <td *ngIf="column.editable && hasScheduledChanges" [class.table-success]="row[colIndex] != table.scheduledData[rowIndex][colIndex]">
                              {{ formatAsantoValue(table.scheduledData[rowIndex][colIndex]) }}
                            </td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                    <p class="p-1" *ngIf="table.isLookup">Lookup columns containing a * character will match any value</p>
                  </ng-container>
                </form>
              </ng-container>

              <form [formGroup]="taxTablesForm">
                <ng-container *ngFor="let taxTable of taxTables; index as taxTableIndex">
                  <!-- Match only tax table type -->
                  <ng-container *ngIf="taxTable.title === text.title">
                    <ng-container *ngIf="showTaxTables(taxTable) else error" formArrayName="tables">
                      <div class="form-group">
                        <div *ngFor="let table of taxTable.tables; index as tableIndex">
                          <label class="d-flex">
                            <input type="radio" value={{table.fiscalYear}} name="{{taxTableIndex}}" formControlName="{{taxTableIndex}}">
                            <span class="p-1">{{getFiscalYearText(table.fiscalYear)}}</span>
                          </label>
                          <table class="table table-bordered">
                            <thead>
                              <!-- If there are scheduled changes, show an additional column for each editable column; one for currently live and one for scheduled. -->
                              <tr>
                                <th
                                  *ngFor="let column of table.columns"
                                  [attr.colspan]="hasScheduledChanges && column.editable ? 2 : 1"
                                  [attr.rowspan]="hasScheduledChanges && !column.editable ? 2 : 1"
                                >
                                  {{ column.header }}
                                </th>
                              </tr>
                              <tr *ngIf="hasScheduledChanges">
                                <ng-container *ngFor="let column of table.columns">
                                  <ng-container *ngIf="column.editable">
                                    <th>Live</th>
                                    <th>Scheduled</th>
                                  </ng-container>
                                </ng-container>
                              </tr>
                            </thead>

                            <tbody class="bg-white">
                              <tr *ngFor="let row of table.data; index as rowIndex">
                                <ng-container *ngFor="let column of table.columns; index as colIndex">
                                  <!-- Column with current value. Show in red if there are scheduled changes that alter this value.
                                  Explicitly using != instead of !== because Asanto sometimes returns strings instead of numbers for example. -->
                                  <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
                                  <td *ngIf="hasScheduledChanges" [class.table-success]="row[colIndex] != table.scheduledData[rowIndex][colIndex]">
                                    {{ formatAsantoValue(table.scheduledData[rowIndex][colIndex]) }}
                                  </td>
                                  <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
                                  <td class="align-middle" [class.table-danger]="hasScheduledChanges && row[colIndex] != table.scheduledData[rowIndex][colIndex]">
                                    {{ formatAsantoValue(row[colIndex], column.type) }}
                                  </td>
                                </ng-container>
                              </tr>
                            </tbody>
                          </table>
                          <p class="p-1" *ngIf="table.isLookup">Lookup columns containing a * character will match any value</p>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </form>
            </ng-template>
          </ngb-panel>
        </ng-container>
      </ngb-accordion>
    </div>
    <div class="text-end" *ngIf="!hasScheduledChanges">
      <!-- Close all active accordions when cancel clicked -->
      <button type="button" class="btn btn-outline-secondary me-2" (click)="cancelChanges()" [disabled]="!anyTablesTouched || isReadOnly" >Cancel</button>
      <button class="btn btn-2022--secondary" (click)="saveChanges()" [disabled]="!anyTablesTouched || isReadOnly">Continue</button>
    </div>
  </div>

<ng-template #updateConfirmationDialog>
  <!-- Preview -->
  <!-- Warning if lender has hosted version -->
  <ng-container *ngIf="lenderIsHosted">
    <div class = "alert alert-warning">
      <p class="p-1">* Changes made for Legal & General Ignite will also reflect in your Hosted Calculator version</p>
    </div>
  </ng-container>
  <ng-container *ngFor="let table of tables; index as tableIndex">
    <h1>{{ table.title }}</h1>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th *ngFor="let column of table.columns">{{ column.header }}</th>
        </tr>
      </thead>
      <tbody *ngIf="tableFormArrays[tableIndex].value as updatedValues">
        <tr *ngFor="let row of table.data; index as rowIndex">
          <td *ngFor="let column of table.columns; index as colIndex">
            {{ formatAsantoValue(updatedValues[rowIndex][colIndex], column.type) }}
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

    <ng-container *ngFor="let taxTable of taxTables; index as taxTableIndex">
        <h1>{{ taxTable.description }}</h1>
      <div class="form-group">
      <div *ngFor="let table of taxTable.tables; index as tableIndex">
      <ng-container *ngIf="taxTableFormArrays.controls[taxTableIndex].value === table.fiscalYear">
        <span>{{getFiscalYearText(table.fiscalYear)}}</span>
        <table class="table table-bordered">
          <thead>
            <!-- If there are scheduled changes, show an additional column for each editable column; one for currently live and one for scheduled. -->
            <tr>
              <th
                *ngFor="let column of table.columns"
                [attr.colspan]="hasScheduledChanges && column.editable ? 2 : 1"
                [attr.rowspan]="hasScheduledChanges && !column.editable ? 2 : 1"
              >
                {{ column.header }}
              </th>
            </tr>
            <tr *ngIf="hasScheduledChanges">
              <ng-container *ngFor="let column of table.columns">
                <ng-container *ngIf="column.editable">
                  <th>Live</th>
                  <th>Scheduled</th>
                </ng-container>
              </ng-container>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr *ngFor="let row of table.data; index as rowIndex">
              <ng-container *ngFor="let column of table.columns; index as colIndex">
                <!-- Column with current value. Show in red if there are scheduled changes that alter this value.
                  Explicitly using != instead of !== because Asanto sometimes returns strings instead of numbers for example. -->
                <!-- eslint-disable-next-line @angular-eslint/template/eqeqeq -->
                <td class="align-middle" [class.table-danger]="hasScheduledChanges && row[colIndex] != table.scheduledData[rowIndex][colIndex]">
                      {{ formatAsantoValue(row[colIndex], column.type) }}
                </td>
              </ng-container>
            </tr>
          </tbody>
        </table>
    </ng-container>
      </div>
    </div>
  </ng-container>

  <!-- Schedule selection and action buttons -->
  <form [formGroup]="scheduleForm">
    <lib-event-schedule
      [scheduleForm]="scheduleForm"
      [alignItems]="'end'"
    ></lib-event-schedule>
    <div class="row mt-3">
      <div class="col">
        <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Go Back</button>
      </div>
      <div class="col text-end">
        <button type="submit" class="btn btn-2022--secondary" (click)="confirmSaveChanges()">Save Changes</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #updateSuccessDialog>
  <div class="alert alert-success">
    <p *ngIf="updatedScheduleDate">
      Your changes have been successfully submitted for update and will be live on
      {{ updatedScheduleDate | date : 'dd/MM/yyyy HH:mm' }}.
    </p>
    <p *ngIf="!updatedScheduleDate">
      Your changes have been successfully updated and will be live in the next few minutes for audit or to view on the
      "Manage Affordability" page
    </p>
    <div class="text-end">
      <a class="btn btn-2022--secondary" [routerLink]="['/']" (click)="closeModal()">Continue</a>
    </div>
  </div>
</ng-template>

<ng-template #rescheduleDialog>
  <form>
    <lib-event-schedule
      [scheduleForm]="scheduleForm"
      [showSetLiveNowOption]="false"
    ></lib-event-schedule>
  </form>
  <div class="row mt-3">
    <div class="col">
      <button type="button" class="btn btn-outline-secondary" (click)="closeModal()">Cancel</button>
    </div>
    <div class="col text-end">
      <button type="submit" class="btn btn-2022--secondary" (click)="confirmUpdateSchedule()">Update Schedule</button>
    </div>
  </div>
</ng-template>

<!-- Error message when no tables are displayed -->
<ng-template #error>
  You are unable to edit at this time, please contact us to discuss
</ng-template>
