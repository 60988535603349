import { ErcOrder, LendingTypeOrder, ProductTypeOrder, SortOrder } from '@msslib/models/filter-sort';

export interface IProductFilterOption {
  lenderNameOrder?: SortOrder;
  lenderNameDescription?: string;
  lendingTypeOrder?: LendingTypeOrder;
  productTypeOrder?: ProductTypeOrder;
  productDescription?: string;
  descriptionOrder?: SortOrder;
  productCodeOrder?: SortOrder;
  productCode?: string;
  maxLtvOrder?: SortOrder;
  aprcOrder?: SortOrder;
  initialPeriodOrder?: SortOrder;
  initialRateOrder?: SortOrder;
  revertRateOrder?: SortOrder;
  cashbackOrder?: SortOrder;
  feesOrder?: SortOrder;
  initialMonthlyPaymentOrder?: SortOrder;
  monthlyPaymentRevertOrder?: SortOrder;
  trueCostTermOrder?: SortOrder;
  ercOrder?: ErcOrder;
}
export enum ProductFilterKey {
  LenderNameOrder = 'lenderNameOrder',
  LenderNameDescription = 'lenderNameDescription',
  LendingTypeOrder = 'lendingTypeOrder',
  ProductTypeOrder = 'productTypeOrder',
  ProductDescription = 'productDescription',
  DescriptionOrder = 'descriptionOrder',
  ProductCodeOrder = 'productCodeOrder',
  ProductCode = 'productCode',
  MaxLtvOrder = 'maxLtvOrder',
  AprcOrder = 'aprcOrder',
  InitialPeriodOrder = 'initialPeriodOrder',
  InitialRateOrder = 'initialRateOrder',
  RevertRateOrder = 'revertRateOrder',
  CashbackOrder = 'cashbackOrder',
  FeesOrder = 'feesOrder',
  InitialMonthlyPaymentOrder = 'initialMonthlyPaymentOrder',
  MonthlyPaymentRevertOrder = 'monthlyPaymentRevertOrder',
  TrueCostTermOrder = 'trueCostTermOrder',
  ErcOrder = 'ercOrder',
}
