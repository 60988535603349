<div class="row">
  <div *ngIf="!hasSchedule" class="col-4 mt-3">
    <div class="bg-2022--white h-100 p-3">
      <h2>{{uploadCardTitle}}</h2>
      <p>Select an Excel file to upload and then click 'Preview upload'.</p>
      <input
        #fileUpload
        type="file"
        id="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <div class="text-end mt-3">
        <button class="btn btn-2022--secondary" (click)="uploadFile(fileUpload.files)">
          Preview upload
        </button>
      </div>
    </div>
  </div>
  <div class="col-{{hasSchedule ? '6' : '4'}} mt-3">
    <div class="bg-2022--white h-100 p-3">
      <h2>{{manageScheduledProductsTitle}}</h2>
      <div *ngIf="hasSchedule; else noSchedule" class="row mt-2">
        <div class="col-12">
          <p>Use the buttons below to view, reschedule or delete your scheduled products.</p>
          <p>
            If you want to upload a new/replacement product list you will need to cancel your existing scheduled products using the
            delete button below.
          </p>
          <p>Products uploaded on {{ uploadDateTime }} scheduled to go live on {{ startDateTime }}</p>
        </div>
        <div class="col-12">
          <button class="btn btn-2022--secondary me-2" [routerLink]="getAllProductsLink" [queryParams]="{ scheduled: 'true', exclusiveOnly: exclusiveOnly ? 'true' : 'false' }">
            {{manageScheduledBtnText}}
          </button>
          <button class="btn btn-2022--secondary me-2" (click)="this.rescheduleScheduleModal()">Reschedule</button>
          <button class="btn btn-2022--secondary" (click)="this.deleteScheduleModal()">Delete</button>
        </div>
      </div>

      <ng-template #noSchedule>
        There are no scheduled products, <a [routerLink]="getAllProductsLink" [queryParams]="{ exclusiveOnly: exclusiveOnly ? 'true' : 'false' }">click here</a> to view current products.
      </ng-template>
    </div>
  </div>

  <div class="col-{{hasSchedule ? '6' : '4'}} mt-3" *ngIf="!hasRole(roles.packager) && !exclusiveOnly">
    <div class="bg-2022--white h-100 p-3" *ngIf="canEditShowProducts">
      <h2>Product visibility</h2>
      <p class="text-muted">{{productVisibilityText}}</p>
      <div *ngIf="productsVisibilityStates && productsVisibilityStates.length !== 1; else singularProductType">
        <ng-container *ngFor="let productsVisibilityState of productsVisibilityStates">
          <div class="form-group">
            <app-products-visibility-checkbox
              [lenderName]="lenderName"
              [lendingTypeCode]="productsVisibilityState.lendingTypeCode"
              [lendingTypeName]="productsVisibilityState.lendingTypeName"
              [lendingTypes]="lendingTypes"
              [isBridging]="isBridging"
              [hasSchedule]="hasSchedule"
              [isChecked]="productsVisibilityState.showProducts"
              [productsVisibilityEnabled]="productsVisibilityState.productsVisibilityEnabled"
              [existingProducts]="existingProducts"
              (showProductsChange)="showProductsStatusChange($event)"
            ></app-products-visibility-checkbox>
          </div>
        </ng-container>
      </div>

      <ng-template #singularProductType>
        <div *ngIf="productsVisibilityStates">
          <div class="form-group">
            <lib-switch
              [disabled]="!existingProducts[formatLendingTypeString(productsVisibilityStates?.[0].lendingTypeName)] && !hasSchedule"
              [checked]="productsVisibilityStates?.[0].showProducts"
              (checkedChange)="showProductsChange($event, productsVisibilityStates?.[0].lendingTypeCode)"
              *ngIf="['RES', 'BTL'].includes(productsVisibilityStates?.[0].lendingTypeCode)"
            >
              Show {{ productsVisibilityStates?.[0].lendingTypeName }} products
            </lib-switch>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #rescheduleUploadModalTemplateRef>
  <lib-reschedule-upload-modal
    [startDate]="scheduleDateTime"
    [willRescheduleVariableRateSchedule]="areProductVariableRateSchedulesLinked"
  ></lib-reschedule-upload-modal>
</ng-template>
