<lib-loading></lib-loading>

<ng-template modal>
  <lib-modal-component></lib-modal-component>
</ng-template>

<lib-toast aria-live="polite" aria-atomic="true"></lib-toast>

<header>
<app-header></app-header>
</header>

<main>
  <router-outlet></router-outlet>
</main>

<lib-footer [showDisclaimer]="false" class="footer"></lib-footer>
