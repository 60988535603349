import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  ICriteriaUpdateHistory,
  IRecentlyUpdatedContext,
  IUnverifiedContext,
  IVerificationDates,
} from '@msslib/models/lender-criteria';
import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import {
  ICriteriaUpdateItem,
  IOutcomeQuestionsResultContext,
  IUpdateLenderNodeNote,
  IUpdateOutcomes,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class LenderCriteriaService {
  public recentCriteria: IRecentlyUpdatedContext[];
  public verificationDates: IVerificationDates;
  public verificationPercentage: number;

  public constructor(private lenderHubDataService: LenderHubDataService) {}

  public getLenderCriteria() {
    return this.lenderHubDataService
      .get<IRecentlyUpdatedContext[]>('LenderCriteria/GetLenderCriteria')
      .pipe(tap((res) => (this.recentCriteria = res)));
  }

  public getVerificationDates(lendingTypeId: number) {
    return this.lenderHubDataService
      .get<IVerificationDates>(`LenderCriteria/GetVerificationDates/${lendingTypeId}`)
      .pipe(tap((res) => (this.verificationDates = res)));
  }

  public getVerificationPercentage(lendingTypeId: number) {
    return this.lenderHubDataService
      .get<number>(`LenderCriteria/GetVerificationPercentage/${lendingTypeId}`)
      .pipe(tap((res) => (this.verificationPercentage = res)));
  }

  public getLenderOutcomes(contextId: number, lenderId: number) {
    return this.lenderHubDataService
      .get<ICriteriaUpdateItem[]>(`LenderOutcome/GetLenderOutcomes/${contextId}/${lenderId}`);
  }

  public getSCLenderOutcomes(contextId: number, lenderId: number) {
    return this.lenderHubDataService
      .get<ICriteriaUpdateItem[]>(`SCLenderOutcome/SCGetLenderOutcomes/${contextId}/${lenderId}`);
  }

  public getSCLenderOutcomesIntuitive(contextId: number, lenderId: number, skipList: unknown) {
    return this.lenderHubDataService.post<IOutcomeQuestionsResultContext>(
      `SCLenderOutcome/SCGetLenderOutcomesIntuitive/${contextId}/${lenderId}`,
      skipList,
    );
  }

  public getSCLenderOutcomesIntuitiveForGroups(lenderId: number, contextIds: number[], skipList: unknown) {
    return this.lenderHubDataService.post<IOutcomeQuestionsResultContext | null>(
      `SCLenderOutcome/SCGetLenderOutcomesIntuitiveForGroups/${lenderId}`,
      {
        contextIds: contextIds,
        skipList: skipList,
      });
  }

  public updateLenderOutcomes(lenderId: number, updateModel: IUpdateOutcomes) {
    return this.lenderHubDataService.post<unknown>(`LenderOutcome/UpdateLenderOutcomes/${lenderId}`, updateModel);
  }

  public getCriteriaUpdateHistory(lendingTypeId: number): Observable<ICriteriaUpdateHistory[]> {
    return this.lenderHubDataService
      .get<ICriteriaUpdateHistory[]>(`LenderCriteria/GetCriteriaUpdateHistory/${lendingTypeId}`);
  }

  public getUnverifiedContexts(lendingTypeId: number): Observable<IUnverifiedContext[]> {
    return this.lenderHubDataService.get<IUnverifiedContext[]>(`LenderCriteria/GetUnverifiedContexts/${lendingTypeId}`);
  }

  public getLenderOutcomesDeep(contextId: number, lenderId: number) {
    return this.lenderHubDataService
      .get<ICriteriaUpdateItem[]>(`LenderOutcome/GetLenderOutcomesByIssue/${contextId}/${lenderId}`);
  }

  public extendVerifiedContexts(lendingTypeId: number): Observable<unknown> {
    return this.lenderHubDataService.get(`LenderCriteria/ExtendVerifiedContexts/${lendingTypeId}`);
  }

  public getLenderNodeNote(lenderId: number, nodeId: number) {
    return this.lenderHubDataService.get<string | null>(`LenderOutcome/GetNodeNote/${nodeId}/${lenderId}`);
  }

  public updateLenderNodeNote(noteModel: IUpdateLenderNodeNote) {
    return this.lenderHubDataService.post<unknown>('LenderOutcome/SaveNodeNote', noteModel);
  }
}
