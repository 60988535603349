import { AfterViewInit, Component, OnInit } from '@angular/core';

import { MiCompletionsPaidService } from './mi-completions-paid.service';
import { IEmbedConfig, IMiCompletionsReport } from './mi-completions-paid.model';
import { AnalyticsService } from '@msslib/services/analytics.service';

@Component({
  selector: 'app-completions-mi-paid',
  styleUrls: ['mi-completions-paid.component.scss'],
  templateUrl: 'mi-completions-paid.component.html',
  providers: [MiCompletionsPaidService],
})
export class MiCompletionsPaidComponent implements OnInit, AfterViewInit {
  public miCompletionsReports: IMiCompletionsReport[];
  public embedConfig: IEmbedConfig;

  public constructor(
    private analyticsService: AnalyticsService,
    private miCompletionsPaidService: MiCompletionsPaidService,
  ) {}

  public ngOnInit() {
    this.miCompletionsPaidService.getReports().subscribe(reports => {
      this.miCompletionsReports = reports;
      this.reportChange(reports[0].id);
    });
  }

  public ngAfterViewInit() {
    this.analyticsService.log('Completions MI');
  }

  public reportChange(reportId: string) {
    this.miCompletionsPaidService.getReportConfiguration(reportId).subscribe(config => {
      this.embedConfig = config;
    });
  }
}
