<div [formGroup]="interestRatesAndOtherCosts">
  <div *ngIf="isIllustration" class="mb-4 row">
    <label class="col-12" [for]="id + 'Text'">Please provide Interest Rates and Other Costs wording:</label>
    <div class="col-12">
      <ckeditor
        [editor]="editor"
        [config]="editorConfig"
        (ready)="onReady($event)"
        formControlName="text"
        [id]="id + 'Text'"
        [ngClass]="{ 'is-valid': valid('text'), 'is-invalid': invalid('text') }"
      >
      </ckeditor>
    </div>
  </div>
  <div class="mb-4 row">
    <label class="col-12" [for]="id + 'Text'">
      Please provide wording for the costs are not known to the lender and are therefore not included in the APRC:
    </label>
    <div class="col-12">
      <ckeditor
        [editor]="editor"
        [config]="editorCostsNotKnownToLenderConfig"
        (ready)="onReady($event)"
        formControlName="costsNotKnownToLender"
        [id]="id + 'Text'"
        [ngClass]="{ 'is-valid': valid('text'), 'is-invalid': invalid('text') }"
      >
      </ckeditor>
    </div>
  </div>
</div>
