<div class="col-4">
  <h2>Product transfer information</h2>
  <form [formGroup]="productTransferForm" (ngSubmit)="onSubmit(productTransferModel)">
    <formly-form
      [fields]="productTransferFields"
      [form]="productTransferForm"
      [model]="productTransferModel"
      [options]="productTransferOptions"
      (modelChange)="modelChange($event)"
    ></formly-form>
    <span *ngIf="verifyError" class="text-2022--danger">Please verify and confirm the retention URL</span>
    <div class="text-end mt-3">
      <button type="submit" class="btn btn-2022--secondary">
        Update
      </button>
    </div>
  </form>
</div>


