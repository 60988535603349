<div class="col-6 mt-3" *ngIf="variableRateCodes">
  <div class="bg-2022--white h-100 p-3">
    <h2 *ngIf="variableRateCodes.enabled">Variable Rate Codes</h2>
    <h2 *ngIf="!variableRateCodes.enabled">Variable Rate Codes (currently inactive)</h2>
    <p>Add new rates or update existing. Press Update when finished.</p>
    <lib-variable-rate-codes-editor
      [form]="variableRateCodeForm"
      [scheduledDate]="variableRateCodes.scheduledDate"
      [variableRateCodes]="variableRateCodes.codes"
      [isProductAdminView]="true"
      (deleteVariableRateCode)="deleteVariableRateCode($event)"
    />
    <ng-container *ngIf="variableRateCodes.codes.length">
      <lib-event-schedule [scheduleForm]="variableRateCodeScheduleForm" />
      <div class="mt-3 text-end">
        <span *ngIf="hasUnsavedChanges" class="text-danger px-3">You have unsaved changes.</span>
        <button class="btn btn-2022--secondary px-4"
          [disabled]="isUpdateButtonDisabled"
          (click)="updateVariableRateCodes()"
          data-testid="variable_rates_update_btn"
        >
          Update
        </button>
      </div>
    </ng-container>
  </div>
</div>
