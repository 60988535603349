import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import type { AffordabilityManagementResponse, AffordabilityManagementUpdateRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AffordabilityManagementService {
  public constructor(private lenderHubDataService: LenderHubDataService) {}

  public getTables(lendingTypeCode: string): Observable<AffordabilityManagementResponse> {
    return this.lenderHubDataService.get<AffordabilityManagementResponse>(`AffordabilityManagement/${lendingTypeCode}`);
  }

  public updateTables(lendingTypeCode: string, updateRequest: AffordabilityManagementUpdateRequest): Observable<void> {
    return this.lenderHubDataService.patch(`AffordabilityManagement/${lendingTypeCode}`, updateRequest);
  }

  public updateScheduledDateTime(lendingTypeCode: string, date: Date): Observable<void> {
    return this.lenderHubDataService.put(`AffordabilityManagement/${lendingTypeCode}/schedule`, date);
  }

  public discardScheduledChanges(lendingTypeCode: string): Observable<void> {
    return this.lenderHubDataService.delete(`AffordabilityManagement/${lendingTypeCode}/schedule`);
  }
}
