<div *ngIf="!isExtendedNonCompliance" [formGroup]="nonCompliance">
  <div class="mb-4 row">
    <label class="col-12" for="nonComplianceText">Please provide 'Consequences for the borrower' wording:</label>
    <div class="col-12">
      <ckeditor
        [editor]="editor"
        [config]="editorConfig"
        (ready)="onReady($event)"
        formControlName="text"
        id="nonComplianceText"
        [ngClass]="{ 'is-valid': valid('nonCompliance', 'text'), 'is-invalid': invalid('nonCompliance', 'text') }"
        required
      >
      </ckeditor>
      <div *ngIf="invalid('nonCompliance', 'text') && text('nonCompliance')?.errors?.['required']" class="invalid-feedback">
        Consequences for the borrower wording is required.
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="isExtendedNonCompliance">
  <div [formGroup]="nonComplianceCI">
    <div class="mb-4 row">
      <label class="col-12" for="nonComplianceCIText">Please provide 'Consequences for the borrower' wording (Capital and Interest / Interest Only Part And Part):</label>
      <div class="col-12">
        <ckeditor
          [editor]="editor"
          [config]="editorConfig"
          (ready)="onReady($event)"
          formControlName="text"
          id="nonComplianceCIText"
          [ngClass]="{ 'is-valid': valid('nonComplianceCI', 'text'), 'is-invalid': invalid('nonComplianceCI', 'text') }"
          required
        >
        </ckeditor>
        <div *ngIf="invalid('nonComplianceCI', 'text') && text('nonComplianceCI')?.errors?.['required']" class="invalid-feedback">
          Consequences for the borrower wording is required.
        </div>
      </div>
    </div>
  </div>
  <div [formGroup]="nonComplianceIO">
    <div class="mb-4 row">
      <label class="col-12" for="nonComplianceIOText">Please provide 'Consequences for the borrower' wording (Interest only):</label>
      <div class="col-12">
        <ckeditor
          [editor]="editor"
          [config]="editorConfig"
          (ready)="onReady($event)"
          formControlName="text"
          id="nonComplianceIOText"
          [ngClass]="{ 'is-valid': valid('nonComplianceIO', 'text'), 'is-invalid': invalid('nonComplianceIO', 'text') }"
          required
        >
        </ckeditor>
        <div *ngIf="invalid('nonComplianceIO', 'text') && text('nonComplianceIO')?.errors?.['required']" class="invalid-feedback">
          Consequences for the borrower wording is required.
        </div>
      </div>
    </div>
  </div>
</ng-container>
