import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ISimpleGridColumn, SimpleGridColumnType } from '@msslib/models';
import { PackagerVariableRateCodeViewModel } from '@msslib/models/variable-rate-codes';
import { ProductService } from 'app/services';

@Component({
  selector: 'app-packager-variable-rates-widget',
  templateUrl: './packager-variable-rates-widget.component.html',
})
export class PackagerVariableRatesWidgetComponent implements OnInit {

  public codes: PackagerVariableRateCodeViewModel[] = [];

  public readonly columns: ISimpleGridColumn[] = [
    { name: 'lender', discreteFilter: true },
    { name: 'code' },
    { name: 'description' },
    {
      name: 'rate', type: SimpleGridColumnType.Number,
      render: (_, { rate, isTracker }: { rate: number; isTracker: boolean }) =>
        `${isTracker ? 'BBR + ' : ''}${this.decimalPipe.transform(rate, '1.2-2')}%`,
    },
  ];

  public constructor(
    private readonly productService: ProductService,
    private readonly decimalPipe: DecimalPipe,
  ) {}

  public ngOnInit(): void {
    this.productService.getPackagerVariableRateCodes().subscribe(c => this.codes = c);
  }
}
