import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '../../../../sentry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { MssLibModule } from '@msslib/msslib.module';
import { ConfigService } from '@msslib/services/config.service';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { environment } from '../environments/environment';
import {
  AffordabilityManagementComponent,
  AllProductsComponent,
  AuditHistoryComponent,
  ConsumerDutyComponent,
  HomeComponent,
  LenderHubHomeCardComponent,
  LenderProductsAdminComponent,
  MiCompletionsPaidComponent,
  MiGenericReportViewerComponent,
  MiHostedComponent,
  MiPageNavComponent,
  MiSurveyingServicesComponent,
  ProductUploadComponent,
  ProductUploadWidgetComponent,
  ProductsVisibilityCheckboxComponent,
} from './pages';
import { ComponentsModule } from './components/components.module';
import { MiSurveyingServicesService } from './pages/mi/mi-surveying-services/mi-surveying-services.service';
import { MssFormlyModule } from '@msslib/components/formly/mss-formly-form.module';
import { MiGenericService } from './pages/mi/mi-generic-report-viewer/mi-generic.service';
import { CommonModule } from '@angular/common';
import { PackagerVariableRatesWidgetComponent } from './pages/product-upload/components';

@NgModule({
  declarations: [
    AppComponent,
    ConsumerDutyComponent,
    HomeComponent,
    LenderHubHomeCardComponent,
    MiCompletionsPaidComponent,
    MiHostedComponent,
    MiPageNavComponent,
    ProductUploadComponent,
    AuditHistoryComponent,
    AffordabilityManagementComponent,
    AllProductsComponent,
    MiSurveyingServicesComponent,
    MiGenericReportViewerComponent,
    ProductUploadWidgetComponent,
    ProductsVisibilityCheckboxComponent,
    LenderProductsAdminComponent,
    PackagerVariableRatesWidgetComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', canceledNavigationResolution: 'computed' }),
    MssLibModule.forRoot(environment.mssLibConfig),
    ComponentsModule,
    MssFormlyModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => () => configService.load(),
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    MiSurveyingServicesService,
    MiGenericService,
  ],
  bootstrap: [AppComponent],
})
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class AppModule {}
