import { Injectable } from '@angular/core';

import { IServiceLevelUpdates } from '../models';
import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';

@Injectable({
  providedIn: 'root',
})
export class LenderServiceLevelUpdatesService {
  public constructor(private lenderHubDataService: LenderHubDataService) {}

  public getServiceLevelUpdates() {
    return this.lenderHubDataService.get<IServiceLevelUpdates>('LenderServiceLevelUpdates');
  }

  public updateServiceLevelUpdates(model: IServiceLevelUpdates) {
    return this.lenderHubDataService.put('LenderServiceLevelUpdates', model);
  }
}
