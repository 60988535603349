import { Injectable } from '@angular/core';
import { roles } from '@msslib/constants';
import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import { Observable } from 'rxjs';
import { IEmbedConfig, IMiGenericReport } from './mi-generic.model';

const reports = [
  { path: 'affordability-mi', displayName: 'Affordability MI', role: roles.lenderPaidMiRoles.affordabilityMi },
  { path: 'affordability-mi-r12', displayName: 'Affordability MI R12 Months',
    role: roles.lenderPaidMiRoles.affordabilityMiR12 },
  { path: 'completions-mi', displayName: 'Lender Completions MI', role: roles.lenderPaidMiRoles.completionsMi },
  { path: 'consumer-duty-mi', displayName: 'Lender Consumer Duty', role: roles.lenderPaidMiRoles.consumerDutyMi },
  { path: 'criteria-mi', displayName: 'Criteria MI', role: roles.lenderPaidMiRoles.criteriaMi },
  { path: 'criteria-mi-r12', displayName: 'Criteria MI R12 Months', role: roles.lenderPaidMiRoles.criteriaMiR12 },
  { path: 'product-mi', displayName: 'Product MI', role: roles.lenderPaidMiRoles.productMi },
  { path: 'product-mi-r12', displayName: 'Product MI R12 Months', role: roles.lenderPaidMiRoles.productMiR12 },
  { path: 'bdm-mi-pack', displayName: 'BDM pack', role: roles.lenderPaidMiRoles.bdmMiPack },
  { path: 'executive-market-summary', displayName: 'Executive Market Summary',
    role: roles.lenderPaidMiRoles.executiveMarketSummary },
  { path: 'property-mi', displayName: 'Property', role: roles.lenderPaidMiRoles.propertyMi },
  {
    path: 'lender-ignite-activity',
    displayName: 'Lender Ignite Activity',
    role: roles.lenderPaidMiRoles.lenderIgniteActivity,
  },
];

@Injectable()
export class MiGenericService {
  public constructor(private dataService: LenderHubDataService) {}

  public static get reports(): typeof reports {
    return reports;
  }

  public getReportByPath(path: string): typeof reports[number] | undefined {
    return reports.find(x => x.path === path);
  }

  public getReports(report: string): Observable<IMiGenericReport[]> {
    return this.dataService.get<IMiGenericReport[]>(`PowerBi/Report/${report}`);
  }

  public getReportConfiguration(report: string, reportId: string): Observable<IEmbedConfig> {
    return this.dataService.get<IEmbedConfig>(`PowerBi/Report/${report}/Configuration/${reportId}`);
  }
}
