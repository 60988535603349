import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
  canDeactivateMessage: () => boolean;
}

export const pendingChangesGuard: CanDeactivateFn<ComponentCanDeactivate> =
  (component: ComponentCanDeactivate) => {
    return component.canDeactivate() ?
      true :
      component.canDeactivateMessage();
  };
