<lib-simple-grid
  [datasource]="codes"
  [columns]="columns"
  [defaultColumnSortable]="false"
  [sortOn]="'lender'"
  [fallbackSort]="[
    ['lender', 'asc'],
    ['code', 'asc']
  ]"
  [paginate]="false" />
