export interface IAuditFilterOption {
  createdDate?: Date;
  approved?: string;
}

export enum FilterKey {
  CreatedDate = 'createdDate',
  AuditType = 'auditType',
  AuditResult = 'auditResult',
}
