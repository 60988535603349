<div [formGroup]="formGroup">
  <div class="mb-4 row">
    <label class="col-12" [for]="id + 'Text'">Please provide {{ placeholder }}:</label>
    <div class="col-12">
      <ckeditor
        [editor]="editor"
        [config]="editorConfig"
        (ready)="onReady($event)"
        formControlName="text"
        [id]="id + 'Text'"
        [ngClass]="{ 'is-valid': valid('text'), 'is-invalid': invalid('text') }"
      >
      </ckeditor>
    </div>
  </div>
</div>