<div class="bg-white p-3">
  <div class="row">
    <div class="col">
      <p class="text-center">
        Your service levels are important, this information can be part of the decision of where to place a mortgage application. If you
        update the service levels below on a weekly basis, the broker will be able to see an accurate view of your services.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col service-level-updates mx-3">
      <div class="row d-flex bg-2022--primary-lighter table-header">
        <div class="col py-2 text-center table-header">Services</div>
        <div class="col py-2 text-center table-header">Waiting time / Facility</div>
      </div>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
        <div class="row d-flex justify-content-end pt-3">
          <button type="submit" class="btn btn-2022--secondary">Update Service Levels</button>
        </div>
      </form>
    </div>
  </div>
</div>
