import { Injectable } from '@angular/core';
import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import { IEmbedConfig, IMiReport } from './mi-surveying-services.model';

@Injectable({ providedIn: 'root' })
export class MiSurveyingServicesService {
  public constructor(private dataService: LenderHubDataService) {}

  public getReports() {
    return this.dataService.get<IMiReport[]>('powerbi/GetSurveyingServicesReports');
  }

  public getReportConfiguration(reportId: string) {
    return this.dataService.get<IEmbedConfig>(`powerbi/GetSurveyingServicesReportConfiguration/${reportId}`);
  }
}
