import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EarlyRepaymentCharges } from 'apps/shared/src/models';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { editorConfig } from '../../editor.config';

@Component({
  selector: 'app-early-repayment-charges',
  templateUrl: 'early-repayment-charges.component.html',
  styleUrls: ['early-repayment-charges.component.scss'],
})

export class EarlyRepaymentChargesComponent implements OnInit {
  @Input() public earlyRepaymentCharges: UntypedFormArray;
  @Input() public earlyRepaymentChargeTerms: UntypedFormGroup;
  @Input() public charges: EarlyRepaymentCharges[];
  @Input() public disabled = false;
  public items: UntypedFormArray;
  public removingItem: number | null = null;
  public editor = DecoupledEditor;
  public editorConfig = {
    ...editorConfig,
    placeholder: 'ERC Additional terms and conditions',
  };

  public constructor(private fb: UntypedFormBuilder) { }

  public ngOnInit() {
    this.charges.forEach((charge) => {
      this.addItem(charge.charge, charge.amount);
    });
  }

  public onReady(editor) {
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element,
    );
  }

  public createItem(charge = '', amount = ''): UntypedFormGroup {
    const formGroup = this.fb.group({
      charge: new UntypedFormControl(charge, Validators.pattern('^[a-zA-Z0-9 _-]+$')),
      amount,
    });
    if (this.disabled) {
      formGroup.disable({ onlySelf: false });
    }
    return formGroup;
  }

  public addItem(charge = '', amount = ''): void {
    this.items = this.earlyRepaymentCharges as UntypedFormArray;
    this.items.push(this.createItem(charge, amount));
  }

  public removeFee(costIndex: number) {
    this.items.removeAt(costIndex);
    this.removingItem = null;
  }

  public valid(name:string, index:number): boolean {
    const control = (this.items.controls[index] as UntypedFormGroup).controls[name];
    return control.valid && control.touched;
  }

  public invalid(name:string, index:number): boolean {
    const control = (this.items.controls[index] as UntypedFormGroup).controls[name];
    return control.invalid && control.touched;
  }

  public getControl(name:string, index:number) {
    return (this.items.controls[index] as UntypedFormGroup).get(name);
  }

  public focusAmount(index:number) {
    (this.items.controls[index] as UntypedFormGroup).get('amount')?.markAsTouched();
  }
}
