<div [formGroup]="lenderDetails">
  <div class="mb-4 row">
    <label class="col-3" for="lenderName">Lender Name to Display on ESIS:</label>
    <div class="col-4">
      <input
        formControlName="lenderName"
        type="text"
        class="form-control"
        [ngClass]="{ 'is-valid': valid('lenderName'), 'is-invalid': invalid('lenderName') }"
        id="lenderName"
        placeholder="Lender Name"
        required
      >
      <div *ngIf="invalid('lenderName') && getControl('lenderName')?.errors['required']" class="invalid-feedback">
        Lender Name is required.
      </div>
      <div *ngIf="invalid('lenderName') && getControl('lenderName')?.errors['pattern']" class="invalid-feedback">
        Lender Name is invalid.
      </div>
    </div>
  </div>
  <div class="mb-4 row">
    <label class="col-3" for="buildingName">Building number and street name:</label>
    <div class="col-4">
      <input
        formControlName="buildingName"
        type="text"
        class="form-control"
        [ngClass]="{ 'is-valid': valid('buildingName'), 'is-invalid': invalid('buildingName') }"
        id="buildingName"
        placeholder="Building number and street name"
        required
      >
       <div *ngIf="invalid('buildingName') && getControl('buildingName')?.errors['required']" class="invalid-feedback">
        Building number and street name is required.
      </div>
      <div *ngIf="invalid('buildingName') && getControl('buildingName')?.errors['pattern']" class="invalid-feedback">
        Building number and street name is invalid.
      </div>
    </div>
  </div>
  <div class="mb-4 row">
    <label class="col-3" for="city">City or town:</label>
    <div class="col-4">
      <input
        formControlName="city"
        type="text"
        class="form-control"
        [ngClass]="{ 'is-valid': valid('city'), 'is-invalid': invalid('city') }"
        id="city"
        placeholder="City or town"
        required
      >
      <div *ngIf="invalid('city') && getControl('city')?.errors['required']" class="invalid-feedback">
        City or town is required.
      </div>
      <div *ngIf="invalid('city') && getControl('city')?.errors['pattern']" class="invalid-feedback">
        City or town is invalid.
      </div>
    </div>
  </div>
  <div class="mb-4 row">
    <label class="col-3" for="state">Province / State:</label>
    <div class="col-4">
      <input
        formControlName="state"
        type="text"
        class="form-control"
        [ngClass]="{ 'is-valid': valid('state'), 'is-invalid': invalid('state') }"
        id="state"
        placeholder="Province / State"
        required
      >
      <div *ngIf="invalid('state') && getControl('state')?.errors['required']" class="invalid-feedback">
        Province / State is required.
      </div>
      <div *ngIf="invalid('state') && getControl('state')?.errors['pattern']" class="invalid-feedback">
        Province / State is invalid.
      </div>
    </div>
  </div>
  <div class="mb-4 row">
    <label class="col-3" for="country">Country:</label>
    <div class="col-4">
      <input
        formControlName="country"
        type="text"
        class="form-control"
        [ngClass]="{ 'is-valid': valid('country'), 'is-invalid': invalid('country') }"
        id="country"
        placeholder="Country"
        required
      >
      <div *ngIf="invalid('country') && getControl('country')?.errors['required']" class="invalid-feedback">
          Country is required.
      </div>
      <div *ngIf="invalid('country') && getControl('country')?.errors['pattern']" class="invalid-feedback">
        Country is invalid.
      </div>
    </div>
  </div>
  <div class="mb-4 row">
    <label class="col-3" for="postcode">Postal Code:</label>
    <div class="col-4">
      <input
        formControlName="postcode"
        type="text"
        class="form-control"
        [ngClass]="{ 'is-valid': valid('postcode'), 'is-invalid': invalid('postcode') }"
        id="postcode"
        placeholder="Postal Code"
        required
      >
      <div *ngIf="invalid('postcode') && getControl('postcode')?.errors['required']" class="invalid-feedback">
        Postal Code is required.
      </div>
      <div *ngIf="invalid('postcode') && getControl('postcode')?.errors['pattern']" class="invalid-feedback">
        Postal Code is invalid.
      </div>
    </div>
  </div>
  <div class="mb-4 row">
    <label class="col-3" for="telephone">Lender's Telephone Number:</label>
    <div class="col-4">
      <input
        formControlName="telephone"
        type="text"
        class="form-control"
        [ngClass]="{ 'is-valid': valid('telephone'), 'is-invalid': invalid('telephone') }"
        id="telephone"
        placeholder="Lender's Telephone Number"
        required
      >
      <div *ngIf="invalid('telephone') && getControl('telephone')?.errors['required']" class="invalid-feedback">
        Lender's Telephone Number is required.
      </div>
      <div *ngIf="invalid('telephone') && getControl('telephone')?.errors['pattern']" class="invalid-feedback">
        Lender's Telephone Number is invalid.
      </div>
    </div>
  </div>
</div>