<app-mi-page-nav></app-mi-page-nav>
<div class="container-fluid">
    <div *ngIf="!isReportView">
      <div class="row mt-3" *ngFor="let report of miReports">
        <a class="link-primary" (click)="reportChange(report.id)">{{report.name}}</a>
      </div>
    </div>
      <div *ngIf="isReportView" class="col-1 mt-3">
      <button type="button" class="btn btn-2022--secondary w-100 h-100" (click)="goBackToList()">
        <i class="fas fa-arrow-left"> Back</i>
      </button>
    </div>
      <div *ngIf="isReportView" class="mt-3">
        <lib-powerbi
          *ngIf="embedConfig"
          type="report"
          [id]="embedConfig.id"
          [embedUrl]="embedConfig.embedUrl"
          [accessToken]="embedConfig.embedToken.token"
          [options]="{ filterPaneEnabled: false }"
        >
        </lib-powerbi>
      </div>
    </div>