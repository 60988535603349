import { Injectable } from '@angular/core';

import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import { IEmbedConfig, IMiCompletionsReport } from './mi-completions-paid.model';

@Injectable()
export class MiCompletionsPaidService {
  public constructor(private dataService: LenderHubDataService) {}

  public getReports() {
    return this.dataService.get<IMiCompletionsReport[]>('powerbi/GetCompletionsReports');
  }

  public getReportConfiguration(reportId: string) {
    return this.dataService.get<IEmbedConfig>(`powerbi/GetCompletionsReportConfiguration/${reportId}`);
  }
}
