<div [formGroup]="additionalInformation">
  <div class="mb-4 row">
    <label class="col-12" for="additionalInformationText">Please provide any additional information wording:</label>
    <div class="col-12">
      <ckeditor
        [editor]="editor"
        [config]="editorConfig"
        (ready)="onReady($event)"
        id="additionalInformationText"
        formControlName="text"
        [ngClass]="{ 'is-valid': valid('text'), 'is-invalid': invalid('text') }"
        required
      >
      </ckeditor>
      <div *ngIf="invalid('text') && text?.errors?.['required']" class="invalid-feedback">
        Additional information wording is required.
      </div>
    </div>
  </div>
</div>