import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { tap } from 'rxjs/operators';

import { ContextOption } from '@msslib/models/contexts';
import { ClubHubDataService } from '@msslib/services/clubhub-data.service';
import { LenderHubService } from './lenderhub.service';

export const lenderHubContextResolver: ResolveFn<ContextOption> =
  (route: ActivatedRouteSnapshot) => {
    const lenderHubService = inject(LenderHubService);
    const clubHubDataService = inject(ClubHubDataService);

    return clubHubDataService.get<ContextOption>(`Answer/GetIssueAndContext/${route.params.contextId}`).pipe(
      tap(response => {
        lenderHubService.currentIssue = response[0];
        lenderHubService.currentContext = response[1];
      }),
    );
  };
