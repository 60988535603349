import { AfterViewInit, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { LenderHubService } from 'apps/shared/src/services';
import { AuthorizeService, IUser, ModalService, UtilsService } from '@msslib/services';
import { roles } from '@msslib/constants';
import { AnalyticsService } from '@msslib/services/analytics.service';
import { DOCUMENT } from '@angular/common';
import { debounceTime } from 'rxjs';
import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import { LenderName } from 'apps/shared/src/models';
import { Params, Router } from '@angular/router';
import { ProductService } from 'apps/lenderhub/src/app/services';
import { ProductsVisibilityState } from '@msslib/models';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
})
export class HomeComponent implements AfterViewInit, OnInit {

  public roles = roles;
  private lenderName: LenderName;
  private productsVisibilityStates: ProductsVisibilityState[];
  private readonly bridgingLendingTypeCode = 'Bdg';

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private lenderHubService: LenderHubService,
    private authService: AuthorizeService,
    private analyticsService: AnalyticsService,
    private utilService: UtilsService,
    private renderer: Renderer2,
    private lenderHubDataService: LenderHubDataService,
    private modalService: ModalService,
    private router: Router,
    private productService: ProductService,
  ) {}

  public ngOnInit() {
    this.lenderHubDataService.get<LenderName>('LenderName').subscribe(
      (lenderName: LenderName) => {
        this.lenderName = lenderName;
        this.setupSightMill();
      });
    this.lenderHubService.updateProductsStatuses();
    if (this.authService.hasRole(roles.product)) {
      this.productService.visibleProductLendingTypes.subscribe((productsVisibilityStates) => {
        this.productsVisibilityStates = productsVisibilityStates;
      });
    }
  }

  public ngAfterViewInit() {
    this.analyticsService.log('LenderHub Home');
  }

  private setupSightMill(): void {
    this.authService.getUser().pipe(debounceTime(2000)).subscribe(
      (user: IUser) => this.showIframe(user),
    );
  }

  private showIframe(user: IUser): void {
    if (user) {
      const script = this.renderer.createElement('script');
      const src = 'assets/js/sightmill-lenderhub.js';
      script.type = 'text/javascript';
      script.src = src;
      script.dataset.email = user.email;
      script.dataset.firmName = this.lenderName?.lenderName;
      if (!this.utilService.scriptExists(src)) {
        this.renderer.appendChild(this.document.head, script);
        this.renderer.setAttribute(script, 'display', 'block');
      }
    }
  }

  public get hasBridgingLendingType(): boolean {
    if (!this.productsVisibilityStates) {
      return false;
    }

    const hasBridging = this.productsVisibilityStates.find(s => s.lendingTypeCode === this.bridgingLendingTypeCode);
    if (hasBridging) {
      return this.hasRole(roles.bridgingLenderHub);
    }

    return false;
  }

  public get lenderDetailsUrl(): string {
    return (
      this.lenderHubService.lenderName?.lenderDetailsUrl ??
      'https://www.legalandgeneral.com/adviser/mortgage-club/lenders/lender-directory'
    );
  }

  public get lenderHasProducts() {
    return this.lenderHubService.lenderName?.hasProducts ?? false;
  }

  public get hasScheduledProducts() {
    return this.lenderHubService.lenderName?.hasSchedule ?? false;
  }

  public get hasLiveProducts() {
    return this.lenderHubService.lenderName?.hasLiveProducts ?? false;
  }

  public hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  public get showServiceLevelUpdates() {
    return this.hasRole(roles.product) ||
      this.hasRole(roles.lenderCriteria) ||
      this.hasRole(roles.affordabilityManager);
  }

  /** Navigates to the given route after confirming the user has agreed to the SF/SP disclaimer. */
  public navigateWithAuditDisclaimer(route: unknown[], queryParams: Params | null = null) {
    this.modalService.warn({
      size: 'sm',
      message: 'By selecting \'Proceed\', you indicate that you will use the Audit Function solely for the purpose of'
        + ' assessing the accuracy of Products.',
      okLabel: 'Proceed',
      hideCancel: true,
    }).then(
      () => this.router.navigate(route, { queryParams }),
      () => { /* Catch to prevent throwing promise rejection error */ },
    );
  }
}
