import { ProductFeeType } from '@msslib/constants';
import { ProductFeeStructure, RateType } from 'apps/shared/src/models';
import { ProductUploadWarning } from './product-upload-additional-result';

export enum AsantoColumnType {
  String = 'string',
  Number = 'number',
  NumberWithUnits = 'numberWithUnits',
  Boolean = 'boolean',
  Date = 'date',
}

export interface UploadedProductsResult {
  products: Record<string, unknown>[];
  warnings: string[];
  additionalFileProcessingResult: {
    hasMissingVariableRateCodes: boolean;
    requiredVariableRateCodes: string[];
    variableRateWarnings: ProductUploadWarning[];
  };
}

export interface ResiBtlProductFee {
  value: number;
  type: ProductFeeType;
  structure: ProductFeeStructure;
}

export interface ResiBtlProductRate {
  ordinal: number;
  rateType: RateType;
  rate: number | null;
  until: string | null;
  initialPeriod: number | null;
  variableRateCode: string | null;
}
