<div class="container mt-3">
  <section class="row">
    <div class="header-image w-100">
      <img src="assets/images/lenderhub/home-page/LH-New-Banner.svg" alt="" />
    </div>
  </section>
</div>
<div class="container lenderhub-home">
  <div class="row">
    <div class="promo-bar col">
      <h2>
        View your audit history
      </h2>
    </div>
  </div>
  <div class="pb-5 px-4">
    <div class="row d-flex table-header w-100 bg-2022--primary-lighter-still">
      <div class="col py-2 text-center table-header">
        <lib-dropdown-filter-datepicker
          name="lendersdropdowndate"
          label="Audit Date"
          (dateChange)="filterResults($event, filterKey.CreatedDate)"
        >
        </lib-dropdown-filter-datepicker>
      </div>
      <div class="col py-2 text-center table-header">User</div>
      <div class="col py-2 text-center table-header">Reference ID</div>
      <div class="col py-2 text-center table-header">
        <lib-dropdown-filter-sorter
          name="lendersdropdownaudittype"
          label="Audit Type"
          [list]="auditTypeFilterOptions"
          [activeValue]="filters.get(filterKey.AuditType)"
          (itemChange)="filterResults($event, filterKey.AuditType)"
        ></lib-dropdown-filter-sorter>
      </div>
      <div class="col py-2 text-center table-header">Lending Type</div>
      <div class="col py-2 text-center table-header">
        <lib-dropdown-filter-sorter
          name="lendersdropdownauditresult"
          label="Audit Result"
          [list]="auditResultFilterOptions"
          [activeValue]="filters.get(filterKey.AuditResult)"
          (itemChange)="filterResults($event, filterKey.AuditResult)"
        ></lib-dropdown-filter-sorter>
      </div>
    </div>
    <div class="row" *ngIf="!history?.length">
      <div class="col py-2 text-center col-cell">
        No audit history results to display.
      </div>
    </div>
    <div *ngFor="let item of history" class="row">
      <div class="col py-2 text-center col-cell">
        {{ formatDate(item.createdDate) }}
      </div>
      <div class="col py-2 text-center col-cell text-break">
        {{ item.createdBy }}
      </div>

      <div class="col py-2 text-center col-cell">
        <a *ngIf="canReplayResult(item); else notReplayable" (click)="replayResult(item)" class="fw-bold pointer">
          <u>{{ item.referenceId }}</u>
        </a>
        <ng-template #notReplayable>
          <span>{{ item.referenceId }}</span>
        </ng-template>
      </div>
      <div class="col py-2 text-center col-cell text-break">
        {{ formatLenderAuditType(item.type) }}
      </div>
      <div class="col py-2 text-center col-cell">
        {{ item.lendingType === 'RES' ? 'Residential' : item.lendingType === 'BTL' ? 'Buy To Let' : 'N/A' }}
      </div>
      <div class="col py-2 text-center col-cell">
        {{ formatLenderAuditResult(item.result) }}
            <button
              *ngIf="isAffordabilityUpdate(item.result)"
              class="btn btn-default p-0"
              [ngbTooltip]="AffordabilityUpdateTemplate"
              placement="right"
              tooltipClass="custom-tooltip"
              triggers="manual"
              [autoClose]="'outside'"
              #t1="ngbTooltip"
              (click)="toggleWithChangedExpressions(t1, item.additionalData?.changedExpressions)"
            >
              <div class="icon-container d-flex align-items-center justify-content-end mx-auto">
                <i class="fas fa-info-circle"></i>
              </div>
            </button>
      </div>
    </div>
  </div>
</div>

<ng-template #AffordabilityUpdateTemplate let-changedExpressions="changedExpressions">
  <b>Updated items:</b>
  <ul class="list-group list-group-flush">
    <li *ngFor="let item of changedExpressions" class="list-group-item">{{item}}</li>
  </ul>
</ng-template>
