import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { editorConfig } from '../../editor.config';

@Component({
  selector: 'app-single-text-editor',
  templateUrl: 'single-text-editor.component.html',
})
export class SingleTextEditorComponent {
  @Input() public id: string;
  @Input() public formGroup: FormGroup<{ text: FormControl<string> }>;
  @Input() public placeholder: string;
  public editor = DecoupledEditor;
  public editorConfig: unknown;

  public constructor() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.editorConfig = {
      ...editorConfig,
      get placeholder() {
        return self.placeholder;
      },
    };
  }

  public onReady(editor) {
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element,
    );
  }

  public get text() {
    return this.formGroup.get('text');
  }

  public valid(name:string): boolean {
    return this.formGroup.controls[name].valid && this.formGroup.controls[name].touched;
  }

  public invalid(name:string): boolean {
    return this.formGroup.controls[name].invalid && this.formGroup.controls[name].touched;
  }
}
