import { Component, OnInit } from '@angular/core';

import { appName } from '@msslib/constants/app-name';

@Component({
  selector: 'app-root',
  styleUrls: ['app.component.scss'],
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {

  public ngOnInit() {
    (window as any).appName = appName.lenderHub;
  }

}
