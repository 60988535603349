import { DropdownFilterType, ISimpleGridColumn, SimpleGridColumnType } from '@msslib/models/simple-grid';
import { BridgingProductType } from 'apps/shared/src/models';

const productTypesDiscreteFilter = () => {
  return Object.keys(BridgingProductType)
    .filter(k => !isNaN(Number(k)))
    .map(type => {
      return {
        label: BridgingProductType[type],
        value: type,
        icon: 'fa-file-contract',
      };
    });
};

export const resiAndBtlProductTableColumns = [
  {
    name: 'productDescription',
    label: 'Product Description',
    headerFilter: 'input',
    sortable: false,
    classList: 'product-no-wrap',
    headerStyle: { 'max-width': '130px' },
  },
  {
    name: 'productCode',
    label: 'Product Code',
    headerFilter: 'input',
    sortable: false,
    classList: 'product-no-wrap',
    headerStyle: { 'max-width': '90px' },
  },
  {
    name: 'type',
    label: 'Type',
    dropdownFilter: DropdownFilterType.None,
    sortable: false,
    classList: 'text-center',
    headerStyle: { 'min-width': '36px' },
    headerTooltip: 'Type',
    icon: 'fa fa-info-circle',
  },
  {
    name: 'lendingType',
    label: 'Lending Type',
    dropdownFilter: DropdownFilterType.None,
    discreteFilter: true,
    sortable: false,
    discreteFilterIcon: 'fa-home',
    classList: 'text-center',
    headerStyle: { 'min-width': '66px' },
  },
  {
    name: 'maxLtv',
    label: 'Max LTV',
    suffix: '%',
    dropdownFilter: DropdownFilterType.None,
    type: SimpleGridColumnType.Number,
    classList: 'text-center',
  },
  {
    name: 'initialPeriod',
    label: 'Initial Period (Months)',
    suffix: ' months',
    dropdownFilter: DropdownFilterType.None,
    discreteFilter: true,
    discreteFilterIcon: 'fa-calendar-alt',
    type: SimpleGridColumnType.Number,
    classList: 'text-center',
  },
  {
    name: 'initialRateValue',
    label: 'Initial Rate',
    suffix: '%',
    dropdownFilter: DropdownFilterType.None,
    type: SimpleGridColumnType.Number,
    classList: 'text-center',
  },
  {
    name: 'initialRateType',
    label: 'Rate Type',
    dropdownFilter: DropdownFilterType.None,
    discreteFilter: true,
    sortable: false,
    discreteFilterIcon: 'fa-file-contract',
    classList: 'text-center',
  },
  {
    name: 'revertRate',
    label: 'Revert Rate',
    dropdownFilter: DropdownFilterType.None,
    suffix: '%',
    type: SimpleGridColumnType.Number,
    classList: 'text-center',
  },
  {
    name: 'earlyRepaymentChargesErcApplied',
    label: 'ERC',
    dropdownFilter: DropdownFilterType.None,
    sortable: false,
    discreteFilter: [
      { label: 'Yes', value: true, icon: 'fa-check-circle' },
      { label: 'No', value: false, icon: 'fa-times-circle' },
      { label: 'Blank', value: null, icon: 'fa-question-circle' },
    ],
    classList: 'text-center',
  },
  {
    name: 'purchaseCashback',
    label: 'Purchase Cashback',
    dropdownFilter: DropdownFilterType.None,
    type: SimpleGridColumnType.Number,
    classList: 'text-center',
  },
  {
    name: 'remortgageCashback',
    label: 'Remortgage Cashback',
    dropdownFilter: DropdownFilterType.None,
    type: SimpleGridColumnType.Number,
    classList: 'text-center',
  },
  {
    name: 'purchaseFreeLegal',
    label: 'Purchase Free Legal',
    classList: 'text-center',
    sortable: false,
    dropdownFilter: DropdownFilterType.None,
    discreteFilter: [
      { label: 'Yes', value: true, icon: 'far fa-lg fa-check-circle text-success' },
    ],
  },
  {
    name: 'remortgageFreeLegal',
    label: 'Remortgage Free Legal',
    classList: 'text-center',
    sortable: false,
    dropdownFilter: DropdownFilterType.None,
    discreteFilter: [
      { label: 'Yes', value: true, icon: 'far fa-lg fa-check-circle text-success' },
    ],
  },
  {
    name: 'purchaseFreeVal',
    label: 'Purchase Free Valuation',
    classList: 'text-center',
    sortable: false,
    dropdownFilter: DropdownFilterType.None,
    discreteFilter: [
      { label: 'Yes', value: true, icon: 'far fa-lg fa-check-circle text-success' },
    ],
  },
  {
    name: 'remortgageFreeVal',
    label: 'Remortgage Free Valuation',
    classList: 'text-center',
    sortable: false,
    dropdownFilter: DropdownFilterType.None,
    discreteFilter: [
      { label: 'Yes', value: true, icon: 'far fa-lg fa-check-circle text-success' },
    ],
  },
  {
    name: 'product_fee_amount_payable',
    label: 'Total Fees',
    dropdownFilter: DropdownFilterType.None,
    type: SimpleGridColumnType.Number,
    classList: 'text-center',
    sortBy: p => (Object.values(p.fees ?? {})).reduce((partialSum: number, a: number) => partialSum + a, 0),
  },
] as ISimpleGridColumn[];

export const bridgingProductTableColumns = [
  { name: 'description', label: 'Product Description', headerFilter: 'input', sortable: false },
  { name: 'code', label: 'Product Code', headerFilter: 'input', sortable: false },
  {
    name: 'maxLtv',
    label: 'Max LTV',
    suffix: '%',
    dropdownFilter: DropdownFilterType.None,
    classList: 'text-center',
    type: SimpleGridColumnType.Number,
  },
  {
    name: 'maxTerm',
    label: 'Max Duration (Months)',
    suffix: ' months',
    dropdownFilter: DropdownFilterType.None,
    discreteFilter: true,
    classList: 'text-center',
    discreteFilterIcon: 'fa-calendar-alt', type: SimpleGridColumnType.Number,
  },
  {
    name: 'rates',
    label: 'Monthly rate',
    suffix: '%',
    dropdownFilter: DropdownFilterType.None,
    sortable: false,
    classList: 'text-center',
    type: SimpleGridColumnType.Number,
  },
  {
    name: 'productType',
    label: 'Product Type',
    dropdownFilter: DropdownFilterType.None,
    sortable: false,
    classList: 'text-center',
    discreteFilter: productTypesDiscreteFilter(),
  },
  {
    name: 'interestMethods',
    label: 'Interest Method',
    dropdownFilter: DropdownFilterType.None,
    discreteFilter: false,
    sortable: false,
    classList: 'text-center',
  },
  {
    name: 'exitFee',
    label: 'Exit Fee',
    dropdownFilter: DropdownFilterType.None,
    sortable: true,
    classList: 'text-center',
    discreteFilter: [
      { label: 'Yes', value: true, icon: 'fa-check-circle' },
      { label: 'No', value: false, icon: 'fa-times-circle' },
      { label: 'Blank', value: null, icon: 'fa-question-circle' },
    ],
  },
  {
    name: 'totalFee',
    label: 'Total Fees',
    dropdownFilter: DropdownFilterType.None,
    type: SimpleGridColumnType.Number,
    sortBy: r => r.totalFee,
    classList: 'text-center',
  },
] as ISimpleGridColumn[];
