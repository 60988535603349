<div class="col-12">
  <h2>Packager Relationship(s)</h2>
  <p>Please select the Packagers you have a relationship with per Lending Type for your Core Products.</p>
  <p>When you upload a Core Product and select 'Yes' for Packager product within the Spreadsheet upload,
    we will upload them for each of the packagers selected here.</p>
  <p>All Exclusives and Semi- Exclusives will be managed via the Packager themselves.</p>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row ms-0 me-1">
      <span class="col-4">Packager name</span>
      <div class="col-8 d-flex justify-content-around">
        <ng-container *ngFor="let lendingType of lenderPackagers?.lenderNameLendingTypes?.lendingTypes">
          <span class="text-center">{{ lendingType?.name }}</span>
        </ng-container>
      </div>

    </div>
    <ul class="border lenders-list list-group">
      <ng-container formArrayName="packagers">
        <li *ngFor="let lender of lenderPackagers?.packagersLendingTypes; let packagerIndex=index"
            class="list-group-item py-1 px-0 border-top-0 border-left-0 border-right-0">
          <ng-container [formGroupName]="packagerIndex">
            <div class="row mx-0">
              <div class="col-4">
                {{ lender?.lenderName }}
              </div>
              <div class="col-8 d-flex justify-content-around">
                <ng-container formArrayName="lendingTypes">
                  <div
                    *ngFor="let lendingType of lenderPackagers?.lenderNameLendingTypes?.lendingTypes; let lendingTypeIndex=index"
                    class="text-center">
                    <ng-container [formGroupName]="lendingTypeIndex">
                      <input
                        formControlName="enabled"
                        class="my-auto checkbox"
                        type="checkbox"
                        [attr.data-testid]="'packager_relationships_enabled_checkbox_'+packagerIndex+'_'+lendingTypeIndex"
                      />
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </li>
      </ng-container>
    </ul>
    <div class="d-flex justify-content-end mt-2">
      <button type="submit" class="btn btn-2022--secondary me-2 button-fixed-size" data-testid="packager_relationships_save_btn">Save</button>
      <button type="button" class="btn btn-2022--secondary button-fixed-size" (click)="selectAll()" data-testid="packager_relationships_select_all_btn">Select All</button>
    </div>
  </form>
</div>
