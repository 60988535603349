import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '@msslib/services/analytics.service';
import { Subscription } from 'rxjs';
import { IEmbedConfig, IMiGenericReport } from './mi-generic.model';
import { MiGenericService } from './mi-generic.service';

@Component({
  selector: 'app-generic-mi-report-viewer',
  templateUrl: 'mi-generic-report-viewer.component.html',
})
export class MiGenericReportViewerComponent implements OnDestroy {
  public miReports: IMiGenericReport[];
  public embedConfig: IEmbedConfig | null;
  private reportPath: string;
  private routeSub: Subscription;

  public constructor(
    activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private miGenericService: MiGenericService,
  ) {
    this.routeSub = activatedRoute.params.subscribe(params => {
      this.reportPath = params.reportpath;
      this.embedConfig = null;
      this.getReports();
    });
  }

  public ngOnDestroy(): void {
    this.routeSub.unsubscribe();
  }

  public getReports() {
    this.miGenericService.getReports(this.reportPath)
      .subscribe(reports => {
        this.miReports = reports;
        this.reportChange(reports[0].id);

        this.analyticsService.log(this.miGenericService.getReportByPath(this.reportPath)?.displayName);
      });
  }

  public reportChange(reportId: string) {
    this.miGenericService.getReportConfiguration(this.reportPath, reportId)
      .subscribe(config => {
        this.embedConfig = config;
      });
  }
}
