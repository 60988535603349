import { Component } from '@angular/core';

import { AuthorizeService } from '@msslib/services/authorize.service';
import { LenderHubService } from 'apps/shared/src/services';
import { roles, userGuideNames } from '@msslib/constants';
import { UserGuideService } from '@msslib/services/user-guide.service';
import { ClientApps } from '@msslib/models/enums';
import { MiGenericService } from '../../pages/mi/mi-generic-report-viewer/mi-generic.service';

@Component({
  selector: 'app-header',
  styleUrls: ['header.component.scss'],
  templateUrl: 'header.component.html',
})
export class HeaderComponent {
  public roles = roles;
  public clientApp: ClientApps = ClientApps.LenderHub;

  public constructor(
    public authService: AuthorizeService,
    public lenderHubService: LenderHubService,
    private userGuideService: UserGuideService,
  ) {}

  public get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  public hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  public get hasPaidCompletionsMi() {
    return this.authService.hasRole(roles.lenderPaidMiRoles.paidCompletionsMi);
  }

  public get hasHostedMi() {
    return this.authService.hasRole(roles.lenderPaidMiRoles.hostedMi);
  }

  public get hasLGSSLenderSpecificMI() {
    return this.authService.hasRole(roles.lenderPaidMiRoles.lGSSLenderSpecificMI);
  }

  public get hasLGSSNewBuildMI() {
    return this.authService.hasRole(roles.lenderPaidMiRoles.lGSSNewBuildMI);
  }

  public get hasAccessToMi() {
    // Check for both paid MI and LGSS to show MI Insights tab as LGSS is not under LenderPaidMi role
    const paidMi = this.authService.hasRole(roles.lenderPaidMi);
    const lgss = this.authService.hasRole(roles.lenderPaidMiRoles.lGSSLenderSpecificMI)
      || this.authService.hasRole(roles.lenderPaidMiRoles.lGSSNewBuildMI);
    return paidMi || lgss;
  }

  public get firstMiLink() {
    if (this.hasPaidCompletionsMi) {
      return '/mi/completions-paid';
    } else if (this.hasHostedMi) {
      return '/mi/hosted';
    } else if (this.hasLGSSLenderSpecificMI || this.hasLGSSNewBuildMI) {
      return '/mi/surveying-services';
    }

    const firstGenericReport = MiGenericService.reports.find(x => this.authService.hasRole(x.role));
    if (!!firstGenericReport) {
      return `/mi/${firstGenericReport.path}`;
    }

    return null;
  }

  public get userGuideLink() {
    return this.userGuideService.getGuideUrl(userGuideNames.lenderHub);
  }

  public get imgIsSvg() {
    return (this.lenderHubService.lenderName?.lenderLogoUrl ?? '').endsWith('.svg');
  }
}
