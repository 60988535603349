<lib-header [client]="clientApp">
  <ul class="nav navbar-nav flex-grow-1 justify-content-center mb-1">
    <li *ngIf="isLoggedIn" class="nav-item">
      <a class="nav-link" [routerLink]="['/home']" routerLinkActive="tab-active">
        Home
      </a>
    </li>
    <!-- <li *ngIf="isLoggedIn && hasRole(roles.lenderCriteria)" class="nav-item">
      <a class="nav-link" [routerLink]="['/criteria']" routerLinkActive="tab-active">
        Criteria v1
      </a>
    </li> -->
    <li *ngIf="isLoggedIn && hasRole(roles.lenderCriteria)" class="nav-item">
      <a class="nav-link" [routerLink]="['/criteria-v2/dashboard']" routerLinkActive="tab-active">
        Criteria
      </a>
    </li>
    <li *ngIf="isLoggedIn && hasAccessToMi" class="nav-item">
      <a class="nav-link" [routerLink]="firstMiLink" routerLinkActive="tab-active">
        MI &amp; Insights
      </a>
    </li>
    <li *ngIf="!hasRole(roles.packager)" class="nav-item">
      <a [href]="userGuideLink" target="_blank" class="nav-link">User Guide</a>
    </li>
    <li class="nav-item">
      <a [routerLink]="['/contact']" class="nav-link" routerLinkActive="tab-active">Contact</a>
    </li>
  </ul>
  <span class="navbar-text pt-0 pb-3 me-4">
    <img
      class="header-lender-logo"
      [class.w-100]="imgIsSvg"
      *ngIf="lenderHubService.lenderName?.lenderLogoUrl"
      [src]="lenderHubService.lenderName?.lenderLogoUrl"
      alt=""
    />
  </span>
</lib-header>
