import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { editorConfig } from '../../editor.config';

@Component({
  selector: 'app-interest-rates-and-other-costs',
  templateUrl: 'interest-rates-and-other-costs.component.html',
})

export class InterestRatesAndOtherCostsComponent {
  @Input() public id: string;
  @Input() public interestRatesAndOtherCosts: UntypedFormGroup;
  @Input() public isIllustration: boolean;
  public editor = DecoupledEditor;
  public editorConfig = { ...editorConfig };
  public editorCostsNotKnownToLenderConfig = { ...editorConfig };

  public constructor() {
    this.editorConfig.placeholder = 'Interest Rates and Other Costs wording';
    this.editorCostsNotKnownToLenderConfig.placeholder = 'Costs not known to the Lender wording';
  }
  public onReady(editor) {
    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element,
    );
  }

  public valid(name:string): boolean {
    return this.interestRatesAndOtherCosts.controls[name].valid &&
      this.interestRatesAndOtherCosts.controls[name].touched;
  }

  public invalid(name:string): boolean {
    return this.interestRatesAndOtherCosts.controls[name].invalid &&
      this.interestRatesAndOtherCosts.controls[name].touched;
  }

  public getControl(name: string) {
    return this.interestRatesAndOtherCosts.get(name);
  }
}
