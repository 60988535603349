<div class="lenderhub-home-section pt-3">
  <figure *ngIf="image">
    <img src="assets/images/{{ image }}" alt="" />
  </figure>
  <div class="home-card-body">
    <h2>{{ title }}</h2>
    <div class="home-card-content">
      <ng-content select="[card-content]"></ng-content>
    </div>
    <div class="home-card-actions">
      <ng-content select="[card-actions]"></ng-content>
    </div>
  </div>
</div>
