import { Injectable } from '@angular/core';

import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';
import { ProductTransferInfoModel } from 'apps/shared/src/models';

@Injectable({
  providedIn: 'root',
})
export class LenderProductTransferService {
  public constructor(private lenderHubDataService: LenderHubDataService) {}

  public getProductTransfer(lenderNameId: number) {
    return this.lenderHubDataService.get<ProductTransferInfoModel>(`ProductTransfer/${lenderNameId}`);
  }

  public updateProductTransfer(lenderNameId: number, model: ProductTransferInfoModel) {
    return this.lenderHubDataService.put(`ProductTransfer/${lenderNameId}`, model);
  }
}
