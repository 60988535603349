<div class="container lenderhub-home mt-3">
  <section class="row">
    <div class="header-image w-100">
      <img src="assets/images/lenderhub/home-page/LH-New-Banner.svg" alt="" />
    </div>
  </section>

  <section class="pb-5 row mt-2">
    <div class="col-12 px-0">
      <h2>
        Your products
      </h2>

      <div class="float-end">
        <div class="form-group">
          <lib-switch
            class="schedule-switch"
            [checked]="showScheduled"
            (checkedChange)="showScheduledProducts($event)"
            [showWarning]="false"
          >
            Show scheduled products
          </lib-switch>
        </div>
      </div>
    </div>

    <div *ngIf="finishedInitialLoading" class="col-12 px-0">
      <div *ngIf="!showScheduled && startTime" class="text-end">Updated on {{ startTime }}</div>
      <div *ngIf="showScheduled && startTime" class="text-end">Scheduled to go live on {{ startTime }}</div>
    </div>

    <div class="col-12 px-0">
      <ng-container *ngIf="matchedProducts?.products && finishedInitialLoading; else noProductsMessage">
        <div class="mob-none d-print-none d-flex justify-content-end">
          <div class="small-text d-flex flex-row mx-0 my-1">
            <div class="align-items-center justify-content-center">
              <i class="far fa-lg fa-check-circle text-success"></i>
              &nbsp; Yes
            </div>
            <div class="ms-4 align-items-center justify-content-center">
              <i class="far fa-lg fa-times-circle text-danger"></i>
              &nbsp; No
            </div>
          </div>
        </div>
        <!-- Matched Products -->
        <lib-simple-grid
          [datasource]="matchedProducts?.products"
          [columns]="productTableColumns"
          [sortOn]="'trueCost'"
          [sortDir]="'asc'"
        >
          <ng-container *ngIf="isBridging">
            <ng-template sgCellTemplate [columnName]="'productType'" let-data>
              {{ bridgingProductsHelper.productTypeToString(data) }}
            </ng-template>
            <ng-template sgCellTemplate [columnName]="'interestMethods'" let-data>
              {{ bridgingProductsHelper.interestMethodsToString(data) }}
            </ng-template>
            <ng-template sgCellTemplate [columnName]="'exitFee'" let-data>
              {{ bridgingProductsHelper.exitFeeToString(data) }}
            </ng-template>
            <ng-template sgCellTemplate [columnName]="'rates'" let-data>
              {{ bridgingProductsHelper.monthlyRateToString(data) }}
            </ng-template>
            <ng-template sgCellTemplate [columnName]="'totalFee'" let-row="row">
              <lib-bridging-product-fees-tooltip [product]="row"></lib-bridging-product-fees-tooltip>
            </ng-template>
          </ng-container>
          <ng-template sgCellTemplate columnName="type" let-row="row">
            <lib-type-product-column [useProductTransferAvailability]="true" [product]="row"></lib-type-product-column>
          </ng-template>
          <ng-template sgCellTemplate [columnName]="'earlyRepaymentChargesErcApplied'" let-row="row">
            <lib-product-erc-tooltip [product]="row"></lib-product-erc-tooltip>
          </ng-template>
          <ng-template sgCellTemplate [columnName]="'product_fee_amount_payable'" let-row="row">
            <lib-product-fees-tooltip [product]="row"></lib-product-fees-tooltip>
          </ng-template>
          <ng-template sgCellTemplate [columnName]="'purchaseCashback'" let-data>
            {{ data | currency: 'GBP':'symbol':'1.0-0' }}
          </ng-template>
          <ng-template sgCellTemplate [columnName]="'remortgageCashback'" let-data>
            {{ data | currency: 'GBP':'symbol':'1.0-0' }}
          </ng-template>

          <ng-template sgCellTemplate [columnName]="'purchaseFreeLegal'" let-row="row">
            <lib-yes-no-column [columnValue]="row.purchaseFreeLegal"></lib-yes-no-column>
          </ng-template>
          <ng-template sgCellTemplate [columnName]="'remortgageFreeLegal'" let-row="row">
            <lib-yes-no-column [columnValue]="row.remortgageFreeLegal"></lib-yes-no-column>
          </ng-template>

          <ng-template sgCellTemplate [columnName]="'purchaseFreeVal'" let-row="row">
            <lib-yes-no-column [columnValue]="row.purchaseFreeVal"></lib-yes-no-column>
          </ng-template>
          <ng-template sgCellTemplate [columnName]="'remortgageFreeVal'" let-row="row">
            <lib-yes-no-column [columnValue]="row.remortgageFreeVal"></lib-yes-no-column>
          </ng-template>

        </lib-simple-grid>
      </ng-container>
      <ng-template #noProductsMessage>
        <div *ngIf="!showScheduled && finishedInitialLoading">
          <p>You currently have no live products on Legal & General Ignite</p>

          <a *ngIf="hasRole(roles.product)" href="/product-upload" class="btn btn-2022--secondary">
            Upload products
          </a>
        </div>

        <div *ngIf="showScheduled && finishedInitialLoading">
          <p>You currently have no products scheduled to go live at a future date</p>
        </div>
      </ng-template>

      <!-- Matched Products -->
    </div>
  </section>
</div>

<ng-template #typeTooltipContent>
  <lib-type-product-column-tooltip></lib-type-product-column-tooltip>
</ng-template>
