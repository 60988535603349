import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthorizeService, ModalService } from '@msslib/services';
import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { roles } from '@msslib/constants';

@Component({
  selector: 'app-lender-products-admin',
  templateUrl: 'lender-products-admin.component.html',
  styleUrls: ['lender-products-admin.component.scss'],
})
export class LenderProductsAdminComponent implements OnInit {
  @ViewChild('nav', { static: true }) public nav: NgbNav;
  @Input({ required: true }) public lenderNameId: number;
  @Input({ required: true }) public isBridging: boolean;

  public hasUnsavedChanges = false;
  public isProductTransferAllowed: boolean;
  public isPackagerRelationshipsAllowed: boolean;

  public constructor(
    public authService: AuthorizeService,
    private modalService: ModalService,
  ) {
  }

  public ngOnInit(): void {
    this.isProductTransferAllowed = !this.authService.hasRole(roles.packager) && !this.isBridging;
    this.isPackagerRelationshipsAllowed = this.authService.hasRole(roles.lender);
  }

  public onNavChange(event: NgbNavChangeEvent) {
    event.preventDefault();
    this.canCloseActiveTab().then(shouldClose => {
      if (shouldClose) {
        this.hasUnsavedChanges = false;
        this.nav.select(event.nextId);
      }
    });
  }

  /**
   * Determines whether the modal or tab can be closed.
   * If there are unsaved changes, shows the 'Discard changes?' modal window and waits for a response.
   * @returns `true` if the operation should continue (no changes or user has chosen to discard changes), or `false` if
   * the operation should cancel (user has chosen to NOT discard changes).
   */
  public canCloseActiveTab() {
    if (!this.hasUnsavedChanges) {
      return Promise.resolve(true);
    }

    return this.modalService.open({
      title: 'Discard changes?',
      message: 'You have made changes to this tab which have not yet been saved. ' +
        'Would you like to discard these changes?',
      showButtons: true,
      hideTopClose: true,
      sticky: true,
      size: 'md',
      okLabel: 'No, continue editing', // Use 'No' as the Okay because this should be the 'primary' action
      cancelLabel: 'Yes, discard changes', // Use 'Yes' as the cancel because this should be the 'secondary' action
    }).then(() => false, () => true);
  }
}
