import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Animations } from '@msslib/animations/animations';
import { AuthorizeService } from '@msslib/services/authorize.service';
import { roles } from '@msslib/constants';
import { ProductFilterKey } from 'apps/shared/src/models';
import { PaginationService } from '@msslib/services/pagination.service';
import { ClubHubDataService, LenderHubDataService, ModalService, ToastService } from '@msslib/services';
import { Router } from '@angular/router';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

interface LenderDocument {
  lenderName: string;
  lenderNameId: number;
  fileName: string;
  description: string;
  uploadTime: number;
}

@Component({
  selector: 'app-consumer-duty',
  templateUrl: 'consumer-duty.component.html',
  styleUrls: ['consumer-duty.component.scss'],
  animations: [Animations.tableOpenClose],
  providers: [PaginationService],
})
export class ConsumerDutyComponent implements OnInit {

  @ViewChild('deleteDialog') public deleteDialog: TemplateRef<unknown>;
  @ViewChild('fileUpload') public fileUpload:ElementRef;
  @ViewChild('description') public description:ElementRef;

  public roles = roles;
  public productFilterKey = ProductFilterKey;
  public documents:LenderDocument[] = [];
  public documentToDelete: any;
  public error:boolean;
  public errorMessage:string | null = null;

  public constructor(
    private authService: AuthorizeService,
    private lenderHubDataService: LenderHubDataService,
    private clubHubDataService: ClubHubDataService,
    private modalService: ModalService,
    private toastService: ToastService,
    private router: Router,
  ) {
  }

  public ngOnInit(): void {
    if (!this.hasRole(roles.consumerDuty)) {
      this.router.navigateByUrl('/');
    }
    this.getFiles();
  }

  public formatDate(date: string): string {
    return format(parseISO(date), 'dd/MM/yyyy HH:mm');
  }

  public get documentLimitExceeded(): boolean {
    return (this.documents?.length ?? 0) >= 50;
  }

  public hasRole(role: string): boolean {
    return this.authService.hasRole(role);
  }

  public uploadFile(files: FileList, description: string) {
    if (files.length === 0) {
      return;
    }
    if (files[0].size < (25 * 1024 * 1024)) {
      const formData: FormData = new FormData();
      formData.append('file', files[0], files[0].name);
      formData.append('description', description);

      this.lenderHubDataService.post('ConsumerDuty/Documents', formData).subscribe(
        {
          next: (data) => {
            this.error = false;
            this.errorMessage = null;
            data;
          },
          error: (err) => {
            this.toastService.danger('Unable to upload document');
            this.error = true;
            this.errorMessage = err.error.error.message.replace('Bad request: ', '');
          },
          complete: () => {
            this.getFiles();
            this.fileUpload.nativeElement.value = '';
            this.description.nativeElement.value = '';
          },
        },
      );
    } else {
      const fileToLargeMsg = 'The file size of this document is too large. Please upload a file smaller than 25Mb';
      this.error = true;
      this.errorMessage = fileToLargeMsg;
      this.toastService.danger(fileToLargeMsg);
    }
  }

  public getFiles() {
    this.documents = [];
    this.clubHubDataService.get('ConsumerDuty/DocumentList').subscribe({
      next: (result: LenderDocument[]) => {
        result.forEach((document: LenderDocument) => {
          this.documents.push(document);
        });
      },
    });
  }


  public download(fileName: string) {
    this.clubHubDataService.getFile(`ConsumerDuty/Documents?lenderNameId=${this.authService.user?.lender}` +
    `&fileName=${encodeURIComponent(fileName)}`).subscribe({
      next: (result: Blob) => {
        const fileURL = URL.createObjectURL(result);
        const a = document.createElement('a');
        a.href = fileURL;
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
    });
  }

  public openDeleteDocumentModal(fileName: string) {
    this.documentToDelete = fileName;
    this.modalService.open({
      title: 'Confirm Delete',
      template: this.deleteDialog,
      message: 'Are you sure that you want to delete this document?',
    });
  }

  public async deleteDocument() {
    this.lenderHubDataService.delete(`ConsumerDuty/Documents?fileName=${encodeURIComponent(this.documentToDelete)}`)
      .subscribe(
        {
          next: (data) => data,
          error: () => this.toastService.danger('Unable to delete document'),
          complete: () => this.getFiles(),
        },
      );
    this.modalService.close();
  }
}
