import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ISimpleGridColumn } from '@msslib/models';
import { IgniteHelperService, LendingTypeService, ModalService } from '@msslib/services';
import { resiAndBtlProductTableColumns } from 'app/pages/all-products/all-products.helper';
import { ESISService } from 'app/services';
import {
  ESISInformation,
  EsisPreviewFormModel,
  LendingDocumentInformationType,
  MortgageType,
  Product,
  RepaymentMethod,
} from 'apps/shared/src/models';

enum EsisPreviewModalPage {
  Loading,
  Form,
  Preview,
}

@Component({
  selector: 'app-esis-preview-modal',
  templateUrl: './esis-preview-modal.component.html',
  styleUrls: ['./esis-preview-modal.component.scss'],
})
export class EsisPreviewModalComponent implements OnInit {
  @ViewChild('typeTooltipContent', { static: true }) public typeTooltipContent: TemplateRef<unknown>;

  public activePage = EsisPreviewModalPage.Loading;
  public readonly page = EsisPreviewModalPage;
  public readonly mortgageType = MortgageType;
  public readonly repaymentMethod = RepaymentMethod;

  @Input({ required: true }) public esisInformation: ESISInformation;
  @Input({ required: true }) public lendingTypeId: number;
  @Input({ required: true }) public documentType: LendingDocumentInformationType;

  public pdfArrayBuffer: ArrayBuffer;
  public pdfArrayBlob: Blob;

  public availableProducts: Product[] = [];

  // The columns used for this are a subset of the ones used in the all products page, so just pick out the ones we need
  public readonly selectProductColumns: ISimpleGridColumn[] = resiAndBtlProductTableColumns.filter(c => [
    'productDescription',
    'productCode',
    'type',
    'lendingType',
    'maxLtv',
    'initialPeriod',
    'initialRateValue',
    'revertRate',
  ].includes(c.name));

  public readonly form = new FormGroup({
    productId: new FormControl<number | null>(null, {
      validators: [Validators.required, Validators.min(1)],
    }),
    mortgageType: new FormControl<MortgageType | null>(null, {
      validators: [Validators.required],
    }),
    propertyValue: new FormControl<number | null>(null, {
      validators: [Validators.required, Validators.min(1)],
    }),
    loanAmount: new FormControl<number | null>(null, {
      validators: [
        Validators.required,
        (control: AbstractControl<number | null>) => {
          const propertyValue = (control?.parent as FormGroup)?.value.propertyValue ?? 0;
          return Validators.max(+propertyValue * 5)(control);
        },
      ],
    }),
    ioLoanAmount: new FormControl<number | null>(null, {
      validators: [
        Validators.min(1),
        (control: AbstractControl<number | null>) => {
          const loanAmount = (control?.parent as FormGroup)?.value.loanAmount ?? 0;
          return Validators.max(+loanAmount)(control);
        },
        (control: AbstractControl<number | null>) => {
          const methodOfRepayment = (control?.parent as FormGroup)?.value.methodOfRepayment;
          return methodOfRepayment === RepaymentMethod.InterestOnlyPartAndPart
            ? Validators.required(control)
            : null;
        }],
    }),
    repaymentMethod: new FormControl<RepaymentMethod | null>(null, {
      validators: [Validators.required],
    }),
    mortgageTermYears: new FormControl<number | null>(null, {
      validators: [Validators.required, Validators.min(0)],
    }),
    mortgageTermMonths: new FormControl<number | null>(0, {
      validators: [Validators.required, Validators.min(0), Validators.max(11)],
    }),
  }, {
    validators: [
      (fc: AbstractControl) => {
        const { mortgageTermYears, mortgageTermMonths } = fc.value;
        const totalTermMonths = (mortgageTermYears ?? 0) * 12 + (mortgageTermMonths ?? 0);
        return totalTermMonths <= 0 ? { termGreaterThan: true } : null;
      },
      (fc: AbstractControl) => {
        const { mortgageTermYears, mortgageTermMonths } = fc.value;
        return 12 * (mortgageTermYears ?? 0) + (mortgageTermMonths ?? 0) > 40 * 12 ? { termHasMax: true } : null;
      },
    ],
  });

  public readonly trackProductRowByFn = (p: Product) => p.id;

  public constructor(
    private readonly esisService: ESISService,
    private readonly modalService: ModalService,
    private readonly lendingTypeService: LendingTypeService,
    private readonly igniteHelper: IgniteHelperService,
  ) {}

  public ngOnInit(): void {
    const lendingTypeId = this.lendingTypeService.getLendingTypeById(this.lendingTypeId)?.id ?? 0;
    this.esisService.getPreviewProducts(lendingTypeId, this.documentType).subscribe(products => {
      this.availableProducts = products;
      this.activePage = EsisPreviewModalPage.Form;
    });

    const typeColumn = this.selectProductColumns.find(col => col.name === 'type');
    if (typeColumn) {
      typeColumn.headerTooltip = this.typeTooltipContent;
    }
  }

  public get title() {
    return this.documentType === LendingDocumentInformationType.Esis
      ? 'Preview ESIS'
      : 'Preview BTL Illustration';
  }

  public get selectedProductIds(): number[] {
    const { productId } = this.form.value;
    return productId ? [productId] : [];
  }

  public get hideIoLoanAmount(): boolean {
    return this.form.value.repaymentMethod !== RepaymentMethod.InterestOnlyPartAndPart;
  }

  public get ltv(): number | null {
    const { propertyValue, loanAmount } = this.form.value;
    return this.igniteHelper.calculateLtv({ propertyValue, loanAmount });
  }

  public onProductSelected(product: Product): void {
    this.form.patchValue({ productId: product?.id ?? null });
  }

  public onSubmit(): void {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }

    this.activePage = EsisPreviewModalPage.Loading;
    this.esisService.previewESIS(
      this.documentType,
      this.lendingTypeId,
      this.esisInformation,
      this.form.value as EsisPreviewFormModel,
    ).subscribe({
      next: pdfArrayBuffer => {
        // We need to store the ArrayBuffer AND create a blob out of the buffer BEFORE showing the preview screen.
        // This is because pdf.js requires an ArrayBuffer to use, but transfers the buffer to another execution context,
        // meaning that it then becomes no longer available in this context, meaning we wouldn't be able to create a
        // blob out of the ArrayBuffer to download it.
        this.pdfArrayBuffer = pdfArrayBuffer;
        this.pdfArrayBlob = new Blob([pdfArrayBuffer], { type: 'application/pdf' });

        this.activePage = EsisPreviewModalPage.Preview;
      },
      error: () => {
        this.activePage = EsisPreviewModalPage.Form;
      },
    });
  }

  public printPdf(): void {
    const objUrl = URL.createObjectURL(this.pdfArrayBlob);
    window.open(objUrl)?.print();
    this.activePage = EsisPreviewModalPage.Form;
  }

  public dismiss(): void {
    this.modalService.dismiss(null);
  }

  public errors(controlName: string) {
    return (controlName?.length ? this.form.get(controlName) : this.form)?.errors ?? {};
  }

  public invalid(controlName: string) {
    const ctrl = this.form.get(controlName);
    return ctrl?.touched && ctrl.invalid;
  }

  public touched(controlName: string) {
    const ctrl = this.form.get(controlName);
    return ctrl?.touched ?? false;
  }

  public preventUnwanted(event: KeyboardEvent) {
    if ('eE+-.'.includes(event.key)) {
      event.preventDefault();
    }
  }
}
