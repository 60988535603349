import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { tap } from 'rxjs/operators';

import { LendingTypeService } from '@msslib/services/lending-type.service';
import { LendingType } from '@msslib/models/lending-type';
import { LenderHubDataService } from '@msslib/services/lenderhub-data.service';

export const lendingTypeResolver: ResolveFn<LendingType[]> =
  (route: ActivatedRouteSnapshot) => {
    const lendingTypeService = inject(LendingTypeService);
    const lenderHubDataService = inject(LenderHubDataService);

    return lenderHubDataService.get<LendingType[]>('lendingtypes').pipe(
      tap((types: LendingType[]) => {
        lendingTypeService.lendingTypes = types.filter(t => !t.archived);
        lendingTypeService.lendingType = types.find(x => x.id === +route.params.lendingTypeId);
      }),
    );
  };
