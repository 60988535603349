<div class="container lenderhub-home mt-3">
  <section class="row">
    <div class="header-image w-100">
      <img src="assets/images/lenderhub/home-page/LH-New-Banner.svg" alt="" />
    </div>
  </section>

  <section class="pb-5 row mt-2">
    <div class="col-12 px-0">
      <h2>
        Consumer Duty
      </h2>
      <div class="px-0 row">
        <div class="col-4 mt-3">
          <div class="bg-2022--white h-100 p-3">
            <h2>Upload Fair Value Statements</h2>
            <p>Select a PDF file to upload and then click 'Upload'</p>
            <form onsubmit="return false" (submit)="uploadFile(fileUpload.files, description.value)">
              <input
                  #fileUpload
                  type="file"
                  id="file"
                  accept="application/pdf"
                  required
              />
              <div *ngIf="error" class="d-flex gap-3 align-items-center mt-2 alert alert-danger">
                <i class="fas fa-times-circle"></i>
                <span>
                  {{errorMessage}}
                </span>
              </div>
              <div *ngIf="documentLimitExceeded" class="d-flex gap-3 align-items-center mt-2 alert alert-danger">
                <i class="fas fa-times-circle"></i>
                <span>
                  Maximum number of documents has been reached. You will need to delete some documents before you can upload any more
                </span>
              </div>
              <br/>
              <br/>
              <h3>Description</h3>
              <textarea #description name="description" style="width: 100%;" rows="5" maxlength="160" placeholder="Please add a meaningful description for your document as this is what will help identify the right document for the brokers circumstances. This is not editable, make sure you are happy with your input" required></textarea>
              <div class="text-end mt-3">
                  <input value="Upload" type="submit" class="btn btn-2022--secondary" [disabled]="documentLimitExceeded"/>
              </div>
            </form>
          </div>
        </div>
        <div class="col-8 mt-3">
          <table class="table table-striped p-3 m-3 align-middle">
            <thead>
              <tr>
                <th>
                  File Name
                </th>
                <th>
                  Description
                </th>
                <th>
                  Last Updated
                </th>
                <th>
                  Download
                </th>
                <th>
                  Delete
                </th>
              </tr>
            </thead>
            <tbody class="p-3 m-3">
                <tr *ngFor="let document of documents">
                    <td> {{document.fileName}} </td>
                    <td> {{document.description}} </td>
                    <td> {{ formatDate(document.uploadTime) }} </td>
                    <td><button class="btn btn-2022--secondary" (click)="download(document.fileName)">Download <i class="fas fa-download"></i></button></td>
                    <td><button class="btn btn-danger" (click)="openDeleteDocumentModal(document.fileName)">Delete <i class="fas fa-trash"></i></button></td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #deleteDialog>
  <div class="row mt-3">
    <div class="col">
      <button type="button" class="btn btn-outline-secondary" (click)="this.modalService.close()">Cancel</button>
    </div>
    <div class="col text-end">
      <button type="submit" class="btn btn-2022--secondary" (click)="deleteDocument()">OK</button>
    </div>
  </div>
</ng-template>
