import { NodeCascadeMode } from '../models';

export function getCascadedNodes<N extends {
  nodeValue?: number;
  upperRange?: number;
  lowerRange?: number;
} = any>(nodes: N[] | undefined, mode: NodeCascadeMode, selectedOutcome: N): N[] | undefined {
  const lowestValue = (n: N) => n.nodeValue ?? n.lowerRange ?? -Infinity;
  const highestValue = (n: N) => n.nodeValue ?? n.upperRange ?? Infinity;

  switch (mode) {
    case NodeCascadeMode.CascadeToLower:
      return nodes?.filter(other => highestValue(other) <= lowestValue(selectedOutcome));
    case NodeCascadeMode.CascadeToHigher:
      return nodes?.filter(other => lowestValue(other) >= highestValue(selectedOutcome));
  }
}
